export const CHUNK_SIZE = 32;
export const BASE_PATH = VUE_APP_BASE_PATH; // eslint-disable-line no-undef
export const BASE_URL = VUE_APP_BASE_URL; // eslint-disable-line no-undef
export const API_URL = VUE_APP_API_URL; // eslint-disable-line no-undef

export const MONTH_NAMES = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const MONTH_NAMES_ACCUSATIVE = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

export const WEEKDAY_NAMES = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

export const CONTRACT_STATUS_NAMES = {
  none: "Не заключен",
  service: "Активен",
  suspended: "Приостановлен",
  terminated: "Расторгнут",
  concluding: "Не заключен", // assert: is_offer == true
};

export const MARGIN_CONFIRMATION_BLOCK = {
  concluding: {
    text: "Для заключения договора необходимо ознакомиться с документами, подписать их и оплатить гарантийный взнос — ",
    confirmation: "Для подтверждения оплаты гарантийного взноса загрузите платежное поручение",
  },
  is_offer: {
    text: "Для принятия оферты нужно передать необходимые документы и данные, также оплатить гарантийный взнос — ",
    confirmation: "Для подтверждения принятия оферты загрузите платежное поручение об оплате гарантийного взноса",
  },
};

export const EVALUATION_REQUEST_STATUS = {
  await: {
    icon: "",
    text: "Ожидает оценки",
  },
  not_resolved: {
    icon:
      '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.00033 15.8334C3.95024 15.8334 0.666992 12.5501 0.666992 8.50002C0.666992 4.44993 3.95024 1.16669 8.00033 1.16669C12.0504 1.16669 15.3337 4.44993 15.3337 8.50002C15.3337 12.5501 12.0504 15.8334 8.00033 15.8334ZM8.00033 14.5C11.314 14.5 14.0003 11.8137 14.0003 8.50002C14.0003 5.18631 11.314 2.50002 8.00033 2.50002C4.68662 2.50002 2.00033 5.18631 2.00033 8.50002C2.00033 11.8137 4.68662 14.5 8.00033 14.5ZM5.80506 11.6381L8.00033 9.44283L10.1956 11.6381L11.1384 10.6953L8.94314 8.50002L11.1384 6.30476L10.1956 5.36195L8.00033 7.55721L5.80506 5.36195L4.86225 6.30476L7.05752 8.50002L4.86225 10.6953L5.80506 11.6381Z" fill="#EB5757"/>\n' +
      "</svg>\n",
    text: "Вопрос не решен",
  },
  closedLike: {
    icon:
      '<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M13.6663 7.49943C13.6663 6.37778 12.9127 5.49943 11.6663 5.49943H9.61766L9.6362 5.44895C9.65468 5.39897 9.70667 5.26196 9.75725 5.12863L9.75737 5.12833L9.75767 5.12754C9.80256 5.00923 9.84626 4.89403 9.86439 4.84537C9.99115 4.50513 10.0836 4.231 10.1585 3.95952C10.2716 3.54919 10.333 3.1795 10.333 2.83276C10.333 1.85143 9.72759 1.24191 8.83186 0.981088C8.42786 0.863449 8.07621 0.832702 7.66634 0.832764H7.34667L7.14652 1.08202C6.92042 1.36359 6.47633 1.87105 5.96435 2.4561L5.96434 2.4561C5.01984 3.53538 3.84428 4.8787 3.37991 5.55503C3.25942 5.51924 3.13179 5.50003 2.99967 5.50003H1.66634C0.929962 5.50003 0.333008 6.09698 0.333008 6.83336V12.8334C0.333008 13.5697 0.929962 14.1667 1.66634 14.1667H2.99967C3.45776 14.1667 3.86189 13.9357 4.10192 13.5838C4.30417 13.7146 4.52982 13.8238 4.77509 13.9116C5.33664 14.1125 5.89357 14.1742 6.35594 14.1657L10.9997 14.1661C12.8364 14.1661 13.6663 11.4618 13.6663 7.49943ZM5.22426 12.6562C4.63432 12.4451 4.33301 12.1009 4.33301 11.4994V6.83336V6.83276C4.33301 6.67263 4.36263 6.53084 4.42748 6.37942C4.54398 6.10743 5.60096 4.90153 6.60278 3.75856L6.60279 3.75854C7.11984 3.16865 7.6222 2.59551 7.97298 2.17618C8.1406 2.18876 8.29859 2.21452 8.4591 2.26125C8.84113 2.3725 8.99968 2.53211 8.99968 2.83276C8.99968 3.04382 8.95696 3.30097 8.87311 3.60517C8.81044 3.83248 8.72928 4.07301 8.61496 4.37987C8.59873 4.42342 8.55825 4.53012 8.5152 4.64358L8.51483 4.64456C8.46247 4.78256 8.40641 4.93031 8.38565 4.98646C8.29896 5.22088 8.24185 5.39347 8.20278 5.5503C8.0367 6.217 8.20331 6.83276 8.99968 6.83276H11.6663C12.1272 6.83276 12.333 7.07269 12.333 7.49943C12.333 10.6933 11.6764 12.8328 10.9997 12.8328H6.33301L6.30839 12.8332C6.01659 12.8384 5.60676 12.793 5.22426 12.6562ZM1.66634 12.8334V6.83336H2.99967V12.8334H1.66634Z" fill="#1FB186"/>\n' +
      "</svg>\n",
    text: "",
  },
  closed: {
    icon:
      '<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M13.6663 7.49943C13.6663 6.37778 12.9127 5.49943 11.6663 5.49943H9.61766L9.6362 5.44895C9.65468 5.39897 9.70667 5.26196 9.75725 5.12863L9.75737 5.12833L9.75767 5.12754C9.80256 5.00923 9.84626 4.89403 9.86439 4.84537C9.99115 4.50513 10.0836 4.231 10.1585 3.95952C10.2716 3.54919 10.333 3.1795 10.333 2.83276C10.333 1.85143 9.72759 1.24191 8.83186 0.981088C8.42786 0.863449 8.07621 0.832702 7.66634 0.832764H7.34667L7.14652 1.08202C6.92042 1.36359 6.47633 1.87105 5.96435 2.4561L5.96434 2.4561C5.01984 3.53538 3.84428 4.8787 3.37991 5.55503C3.25942 5.51924 3.13179 5.50003 2.99967 5.50003H1.66634C0.929962 5.50003 0.333008 6.09698 0.333008 6.83336V12.8334C0.333008 13.5697 0.929962 14.1667 1.66634 14.1667H2.99967C3.45776 14.1667 3.86189 13.9357 4.10192 13.5838C4.30417 13.7146 4.52982 13.8238 4.77509 13.9116C5.33664 14.1125 5.89357 14.1742 6.35594 14.1657L10.9997 14.1661C12.8364 14.1661 13.6663 11.4618 13.6663 7.49943ZM5.22426 12.6562C4.63432 12.4451 4.33301 12.1009 4.33301 11.4994V6.83336V6.83276C4.33301 6.67263 4.36263 6.53084 4.42748 6.37942C4.54398 6.10743 5.60096 4.90153 6.60278 3.75856L6.60279 3.75854C7.11984 3.16865 7.6222 2.59551 7.97298 2.17618C8.1406 2.18876 8.29859 2.21452 8.4591 2.26125C8.84113 2.3725 8.99968 2.53211 8.99968 2.83276C8.99968 3.04382 8.95696 3.30097 8.87311 3.60517C8.81044 3.83248 8.72928 4.07301 8.61496 4.37987C8.59873 4.42342 8.55825 4.53012 8.5152 4.64358L8.51483 4.64456C8.46247 4.78256 8.40641 4.93031 8.38565 4.98646C8.29896 5.22088 8.24185 5.39347 8.20278 5.5503C8.0367 6.217 8.20331 6.83276 8.99968 6.83276H11.6663C12.1272 6.83276 12.333 7.07269 12.333 7.49943C12.333 10.6933 11.6764 12.8328 10.9997 12.8328H6.33301L6.30839 12.8332C6.01659 12.8384 5.60676 12.793 5.22426 12.6562ZM1.66634 12.8334V6.83336H2.99967V12.8334H1.66634Z" fill="#1FB186"/>\n' +
      "</svg>\n",
    text: "",
  },
  closedDislike: {
    icon:
      '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M10.7577 10.8719C10.707 10.7382 10.6547 10.6006 10.6362 10.5505L10.6177 10.5H12.6663C13.9127 10.5 14.6663 9.62163 14.6663 8.49997C14.6663 4.53759 13.8364 1.83331 11.9997 1.83331C11.9997 1.83331 7.34843 1.83346 7.33229 1.83311C6.89357 1.82525 6.33663 1.88694 5.77509 2.08785C5.52971 2.17565 5.30396 2.28488 5.10164 2.41577C4.86157 2.06413 4.45758 1.83331 3.99967 1.83331H2.66634C1.92996 1.83331 1.33301 2.43026 1.33301 3.16664V9.16664C1.33301 9.90302 1.92996 10.5 2.66634 10.5H3.99967C4.13191 10.5 4.25965 10.4807 4.38025 10.4449C4.84484 11.1214 6.02006 12.4643 6.96432 13.5433L6.96434 13.5433L6.96436 13.5433L6.96438 13.5433C7.47635 14.1284 7.92043 14.6358 8.14652 14.9174L8.34667 15.1666H8.67147C9.07621 15.1667 9.42786 15.136 9.83186 15.0183C10.7276 14.7575 11.333 14.148 11.333 13.1666C11.333 12.8199 11.2716 12.4502 11.1585 12.0399C11.0836 11.7684 10.9912 11.4943 10.8644 11.154C10.8463 11.1056 10.8029 10.9912 10.7583 10.8734L10.758 10.8728L10.7577 10.8719ZM5.33301 9.16664V4.49997C5.33301 3.89849 5.63432 3.55432 6.22426 3.34325C6.60676 3.2064 7.01659 3.161 7.31007 3.16624L11.0222 3.16663L11.9997 3.16664C12.6764 3.16664 13.333 5.30614 13.333 8.49997C13.333 8.92671 13.1272 9.16664 12.6663 9.16664H9.99967C9.20331 9.16664 9.0367 9.7824 9.20278 10.4491C9.24184 10.6059 9.29896 10.7785 9.38565 11.0129C9.40644 11.0692 9.46259 11.2171 9.51499 11.3553L9.51504 11.3554L9.51521 11.3559L9.51542 11.3564C9.55839 11.4696 9.59876 11.5761 9.61496 11.6195C9.72928 11.9264 9.81044 12.1669 9.87311 12.3942C9.95696 12.6984 9.99967 12.9556 9.99967 13.1666C9.99967 13.4673 9.84113 13.6269 9.4591 13.7381C9.29859 13.7849 9.1406 13.8106 8.97298 13.8232C8.6222 13.4039 8.11985 12.8308 7.60281 12.2409L7.60279 12.2409C6.60096 11.0979 5.54398 9.89197 5.42748 9.61998C5.36263 9.46857 5.33301 9.32677 5.33301 9.16664ZM2.66634 9.16664V3.16664H3.99967V9.16664H2.66634Z" fill="#EB5757"/>\n' +
      "</svg>\n",
    text: "",
  },
  closed_auto: {
    icon:
      '<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.00033 14.8334C3.95024 14.8334 0.666992 11.5501 0.666992 7.50002C0.666992 3.44993 3.95024 0.166687 8.00033 0.166687C12.0504 0.166687 15.3337 3.44993 15.3337 7.50002C15.3337 11.5501 12.0504 14.8334 8.00033 14.8334ZM8.00033 13.5C11.3141 13.5 14.0003 10.8138 14.0003 7.50002C14.0003 4.18631 11.3141 1.50002 8.00033 1.50002C4.68662 1.50002 2.00033 4.18631 2.00033 7.50002C2.00033 10.8138 4.68662 13.5 8.00033 13.5ZM11.3337 6.83335H8.66699V3.50002H7.33366V8.16669H11.3337V6.83335Z" fill="#8A8A8A"/>\n' +
      "</svg>\n",
    text: "Закрыто автоматически",
  },
};

export const EVALUATION_BUTTON = {
  like: {
    icon:
      '<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">\\n\' +\n' +
      '        \'<path fill-rule="evenodd" clip-rule="evenodd" d="M13.6663 7.49943C13.6663 6.37778 12.9127 5.49943 11.6663 5.49943H9.61766L9.6362 5.44895C9.65468 5.39897 9.70667 5.26196 9.75725 5.12863L9.75737 5.12833L9.75767 5.12754C9.80256 5.00923 9.84626 4.89403 9.86439 4.84537C9.99115 4.50513 10.0836 4.231 10.1585 3.95952C10.2716 3.54919 10.333 3.1795 10.333 2.83276C10.333 1.85143 9.72759 1.24191 8.83186 0.981088C8.42786 0.863449 8.07621 0.832702 7.66634 0.832764H7.34667L7.14652 1.08202C6.92042 1.36359 6.47633 1.87105 5.96435 2.4561L5.96434 2.4561C5.01984 3.53538 3.84428 4.8787 3.37991 5.55503C3.25942 5.51924 3.13179 5.50003 2.99967 5.50003H1.66634C0.929962 5.50003 0.333008 6.09698 0.333008 6.83336V12.8334C0.333008 13.5697 0.929962 14.1667 1.66634 14.1667H2.99967C3.45776 14.1667 3.86189 13.9357 4.10192 13.5838C4.30417 13.7146 4.52982 13.8238 4.77509 13.9116C5.33664 14.1125 5.89357 14.1742 6.35594 14.1657L10.9997 14.1661C12.8364 14.1661 13.6663 11.4618 13.6663 7.49943ZM5.22426 12.6562C4.63432 12.4451 4.33301 12.1009 4.33301 11.4994V6.83336V6.83276C4.33301 6.67263 4.36263 6.53084 4.42748 6.37942C4.54398 6.10743 5.60096 4.90153 6.60278 3.75856L6.60279 3.75854C7.11984 3.16865 7.6222 2.59551 7.97298 2.17618C8.1406 2.18876 8.29859 2.21452 8.4591 2.26125C8.84113 2.3725 8.99968 2.53211 8.99968 2.83276C8.99968 3.04382 8.95696 3.30097 8.87311 3.60517C8.81044 3.83248 8.72928 4.07301 8.61496 4.37987C8.59873 4.42342 8.55825 4.53012 8.5152 4.64358L8.51483 4.64456C8.46247 4.78256 8.40641 4.93031 8.38565 4.98646C8.29896 5.22088 8.24185 5.39347 8.20278 5.5503C8.0367 6.217 8.20331 6.83276 8.99968 6.83276H11.6663C12.1272 6.83276 12.333 7.07269 12.333 7.49943C12.333 10.6933 11.6764 12.8328 10.9997 12.8328H6.33301L6.30839 12.8332C6.01659 12.8384 5.60676 12.793 5.22426 12.6562ZM1.66634 12.8334V6.83336H2.99967V12.8334H1.66634Z" fill="#1FB186"/>\\n\' +\n' +
      "        '</svg>\n",
    text: "",
    status: "closed",
  },
  dislike: {
    icon:
      '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M10.7577 10.8719C10.707 10.7382 10.6547 10.6006 10.6362 10.5505L10.6177 10.5H12.6663C13.9127 10.5 14.6663 9.62163 14.6663 8.49997C14.6663 4.53759 13.8364 1.83331 11.9997 1.83331C11.9997 1.83331 7.34843 1.83346 7.33229 1.83311C6.89357 1.82525 6.33663 1.88694 5.77509 2.08785C5.52971 2.17565 5.30396 2.28488 5.10164 2.41577C4.86157 2.06413 4.45758 1.83331 3.99967 1.83331H2.66634C1.92996 1.83331 1.33301 2.43026 1.33301 3.16664V9.16664C1.33301 9.90302 1.92996 10.5 2.66634 10.5H3.99967C4.13191 10.5 4.25965 10.4807 4.38025 10.4449C4.84484 11.1214 6.02006 12.4643 6.96432 13.5433L6.96434 13.5433L6.96436 13.5433L6.96438 13.5433C7.47635 14.1284 7.92043 14.6358 8.14652 14.9174L8.34667 15.1666H8.67147C9.07621 15.1667 9.42786 15.136 9.83186 15.0183C10.7276 14.7575 11.333 14.148 11.333 13.1666C11.333 12.8199 11.2716 12.4502 11.1585 12.0399C11.0836 11.7684 10.9912 11.4943 10.8644 11.154C10.8463 11.1056 10.8029 10.9912 10.7583 10.8734L10.758 10.8728L10.7577 10.8719ZM5.33301 9.16664V4.49997C5.33301 3.89849 5.63432 3.55432 6.22426 3.34325C6.60676 3.2064 7.01659 3.161 7.31007 3.16624L11.0222 3.16663L11.9997 3.16664C12.6764 3.16664 13.333 5.30614 13.333 8.49997C13.333 8.92671 13.1272 9.16664 12.6663 9.16664H9.99967C9.20331 9.16664 9.0367 9.7824 9.20278 10.4491C9.24184 10.6059 9.29896 10.7785 9.38565 11.0129C9.40644 11.0692 9.46259 11.2171 9.51499 11.3553L9.51504 11.3554L9.51521 11.3559L9.51542 11.3564C9.55839 11.4696 9.59876 11.5761 9.61496 11.6195C9.72928 11.9264 9.81044 12.1669 9.87311 12.3942C9.95696 12.6984 9.99967 12.9556 9.99967 13.1666C9.99967 13.4673 9.84113 13.6269 9.4591 13.7381C9.29859 13.7849 9.1406 13.8106 8.97298 13.8232C8.6222 13.4039 8.11985 12.8308 7.60281 12.2409L7.60279 12.2409C6.60096 11.0979 5.54398 9.89197 5.42748 9.61998C5.36263 9.46857 5.33301 9.32677 5.33301 9.16664ZM2.66634 9.16664V3.16664H3.99967V9.16664H2.66634Z" fill="#EB5757"/>\n' +
      "</svg>\n",
    text: "",
    status: "closed",
  },
  not_resolved: {
    icon:
      '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">\\n\' +\n' +
      '        \'<path fill-rule="evenodd" clip-rule="evenodd" d="M8.00033 15.8334C3.95024 15.8334 0.666992 12.5501 0.666992 8.50002C0.666992 4.44993 3.95024 1.16669 8.00033 1.16669C12.0504 1.16669 15.3337 4.44993 15.3337 8.50002C15.3337 12.5501 12.0504 15.8334 8.00033 15.8334ZM8.00033 14.5C11.314 14.5 14.0003 11.8137 14.0003 8.50002C14.0003 5.18631 11.314 2.50002 8.00033 2.50002C4.68662 2.50002 2.00033 5.18631 2.00033 8.50002C2.00033 11.8137 4.68662 14.5 8.00033 14.5ZM5.80506 11.6381L8.00033 9.44283L10.1956 11.6381L11.1384 10.6953L8.94314 8.50002L11.1384 6.30476L10.1956 5.36195L8.00033 7.55721L5.80506 5.36195L4.86225 6.30476L7.05752 8.50002L4.86225 10.6953L5.80506 11.6381Z" fill="#EB5757"/>\\n\' +\n' +
      "        '</svg>\n",
    text: "Мой вопрос не решен",
    status: "not_resolved",
  },
};

export const STATUSES_CONTRACT = {
  none: {
    title: "Не заключен",
    color: "#333333",
    background: "#fedd46",
    icon: "",
  },
  service: {
    title: "Договор действует",
    color: "#04c200",
    background: "#ddffdc",
    icon: "@images/tariff/cat-service.png",
  },
  suspended: {
    title: "Договор приостановлен",
    color: "#f55f44",
    background: "#ffe8e4",
    icon: "@images/tariff/cat-suspended.png",
  },
  terminated: {
    title: "Договор расторгнут",
    color: "#848484",
    background: "#f2f2f2",
    icon: "@images/tariff/cat-terminated.png",
  },
  concluding: {
    title: "Договор ожидает подписания",
    color: "#333333",
    background: "#fedd46",
    icon: "@images/tariff/cat-concluding.png",
  },
};

export const allTopicsFilters = {
  Единая: {
    messengers: true,
    messages_types: [
      "email",
      "phonecall",
      "assignment",
      "cabinet_approval",
      "system_journal",
      "system_journal_comment",
      "system_statement_files",
      "system_closing_files",
      "document_journal",
    ],
  },
  Общая: {
    messengers: true,
    messages_types: ["email", "phonecall", "assignment", "cabinet_approval"],
  },
  "Заключение договора": {
    messengers: false,
    messages_types: ["system_journal", "system_journal_comment", "document_journal"],
  },
  Финансы: {
    messengers: false,
    messages_types: ["assignment", "cabinet_approval"],
  },
  "Первичные документы": {
    messengers: false,
    messages_types: ["system_statement_files", "system_closing_files", "assignment", "cabinet_approval"],
  },
  Журналы: {
    messengers: false,
    messages_types: ["document_journal", "system_journal_comment", "assignment", "cabinet_approval"],
  },
  Отчетность: {
    messengers: false,
    messages_types: ["assignment", "cabinet_approval"],
  },
  "Документы 1С:БО": {
    messengers: false,
    messages_types: ["assignment", "cabinet_approval"],
  },
  // all other topics
  undefined: {
    messengers: false,
    messages_types: ["assignment", "cabinet_approval"],
  },
};
