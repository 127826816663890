import uniqBy from "lodash-es/uniqBy";
import property from "lodash-es/property";
import matchesProperty from "lodash-es/matchesProperty";
import findIndex from "lodash-es/findIndex";
import isEmpty from "lodash-es/isEmpty";
import sortBy from "lodash-es/sortBy";

import { CalendarService } from "../../services";

import Filters from "./calendar-filters";
import Datepicker from "./calendar-datepicker";

function resetState() {
  return {
    tasks: [],
    tasks_are_loading: false,

    show_search_results: false,

    channels: undefined,

    reminder_is_loading: false,

    block_scroll_into_view: false,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getTasks({ tasks }, getters) {
      let result;

      const section = getters["Filters/getActiveTab"].key;
      const onlyWithReminders = getters["Filters/getOnlyWithReminder"];
      const status = getters["Filters/getSelectedStatus"](section);

      if (section == "all") {
        result = tasks;
      } else {
        result = tasks.filter(matchesProperty("section", section));
      }

      if (onlyWithReminders) {
        result = result.filter(withReminders);
      }

      if (status && status != "any") {
        result = result.filter(matchesProperty("status", status));
      }

      result = sortBy(result, [byDate, byStatus]);

      return result.filter(
        // hide assignments until next release
        function notAssignment(task) {
          // eslint-disable-next-line no-undef
          if (VUE_APP_HIDE_ASSIGNMENTS_IN_CALENDAR_SECTION) {
            return task.section != "assignment";
          } else {
            return true;
          }
        }
      );

      function withReminders(task) {
        return !isEmpty(task.reminders);
      }

      function byDate(task) {
        return new Date(task.date);
      }

      function byStatus(task) {
        const sortOrder = ["expired", "work", "assigned", "done", "canceled"];

        return sortOrder.indexOf(task.status);
      }
    },

    getShowSearchResults({ show_search_results }) {
      return show_search_results;
    },

    areTasksLoading({ tasks_are_loading }) {
      return tasks_are_loading;
    },

    getChannels({ channels }) {
      return channels;
    },

    getTasksGroupsHeadsIds(state, getters) {
      const tasks = getters["getTasks"];

      return uniqBy(tasks, "date").map(property("id"));
    },

    getTasksGroupsHeadsDates(state, getters) {
      const tasks = getters["getTasks"];

      return uniqBy(tasks, "date").map(property("date"));
    },

    getBlockScrollIntoView({ block_scroll_into_view }) {
      return block_scroll_into_view;
    },
  },

  mutations: {
    SET_TASKS(state, tasks) {
      state.tasks = tasks;
    },

    REPLACE_TASK(state, task) {
      const index = findIndex(state.tasks, ["id", task.id]);

      if (index == -1) return;

      state.tasks.splice(index, 1, task);
    },

    SET_TASKS_ARE_LOADING(state, value) {
      state.tasks_are_loading = value;
    },

    SET_SHOW_SEARCH_RESULTS(state, value) {
      state.show_search_results = value;
    },

    SET_CHANNELS(state, channels) {
      state.channels = channels;
    },

    SET_REMINDER_IS_LOADING(state, value) {
      state.reminder_is_loading = value;
    },

    SET_BLOCK_SCROLL_INTO_VIEW(state, value) {
      state.block_scroll_into_view = value;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    async loadTasks({ commit }, { orgId, params }) {
      commit("SET_TASKS_ARE_LOADING", true);

      try {
        const tasks = await CalendarService.fetchTasks({ orgId, params });

        const finalizedStatuses = ["done", "canceled"];

        tasks.map((task) => {
          const taskNotFinalized = !finalizedStatuses.includes(task.status);

          const taskCantBeTimelyCompleted = new Date(task.date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);

          if (taskCantBeTimelyCompleted && taskNotFinalized) {
            task.status = "expired";
          }

          return task;
        });

        commit("SET_TASKS", tasks);

        if (params.findstr) {
          commit("SET_SHOW_SEARCH_RESULTS", true);
        } else {
          commit("SET_SHOW_SEARCH_RESULTS", false);
        }
      } catch {
        commit("SET_SHOW_SEARCH_RESULTS", false);
      } finally {
        commit("SET_TASKS_ARE_LOADING", false);
      }
    },

    async loadChannels({ commit }) {
      const channelsList = await CalendarService.fetchChannels();

      commit("SET_CHANNELS", channelsList);
    },

    async createReminder({ commit }, { taskId, params }) {
      commit("SET_REMINDER_IS_LOADING", true);

      try {
        const task = await CalendarService.createReminder(taskId, params);

        commit("REPLACE_TASK", task);

        commit("SET_BLOCK_SCROLL_INTO_VIEW", true);
      } finally {
        commit("SET_REMINDER_IS_LOADING", false);

        setTimeout(() => {
          commit("SET_BLOCK_SCROLL_INTO_VIEW", false);
        }, 1000);
      }
    },

    async updateReminder({ commit }, { taskId, params }) {
      commit("SET_REMINDER_IS_LOADING", true);

      try {
        const task = await CalendarService.updateReminder(taskId, params);

        commit("REPLACE_TASK", task);

        commit("SET_BLOCK_SCROLL_INTO_VIEW", true);
      } finally {
        commit("SET_REMINDER_IS_LOADING", false);

        setTimeout(() => {
          commit("SET_BLOCK_SCROLL_INTO_VIEW", false);
        }, 1000);
      }
    },

    async deleteReminder({ commit }, { taskId }) {
      commit("SET_REMINDER_IS_LOADING", true);

      try {
        const task = await CalendarService.deleteReminder(taskId);

        commit("REPLACE_TASK", task);

        commit("SET_BLOCK_SCROLL_INTO_VIEW", true);
      } finally {
        commit("SET_REMINDER_IS_LOADING", false);

        setTimeout(() => {
          commit("SET_BLOCK_SCROLL_INTO_VIEW", false);
        }, 1000);
      }
    },

    resetState({ commit, dispatch }) {
      dispatch("Filters/resetState");
      dispatch("Datepicker/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    Filters,
    Datepicker,
  },
};
