import Vue from "vue";

import spaced from "./spaced";
Vue.filter("spaced", spaced);

import nameInitials from "./nameInitials";
Vue.filter("nameInitials", nameInitials);

import localDate from "./localDate";
Vue.filter("localDate", localDate);

import localDateLong from "./localDateLong";
Vue.filter("localDateLong", localDateLong);

import getTime from "./getTime";
Vue.filter("getTime", getTime);

import localDateAndTime from "./localDateAndTime";
Vue.filter("localDateAndTime", localDateAndTime);

import lowerCase from "./lowerCase";
Vue.filter("lowerCase", lowerCase);

import formatBytes from "./formatBytes";
Vue.filter("formatBytes", formatBytes);

import formatDate from "./formatDate";
Vue.filter("formatDate", formatDate);

export { spaced, nameInitials, localDate, localDateLong, getTime, localDateAndTime, lowerCase, formatBytes };
