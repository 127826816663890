import forEach from "lodash-es/forEach";
import pick from "lodash-es/pick";

import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";

import setTemplateType from "../utils/setTemplateType";
import store from "@/store";

const FeedService = {
  async fetchParticipants(orgId) {
    const url = `/organization/${orgId}/feed/participants`;
    const params = { v2: 1 };

    const response = await axiosClient.get(url, { params });

    return response.data;
  },

  async fetchAttachments(orgId, type, params) {
    let url;

    if (type == "all") {
      url = `/organization/${orgId}/feed/attachments`;
    } else {
      url = `/organization/${orgId}/feed/attachments/${type}`;
    }

    const response = await axiosClient.get(url, { params });

    return response.data;
  },

  async fetchAttachmentsInfo({ orgId, topicId }) {
    const url = `/organization/${orgId}/feed/attachments/info`;
    const params = {
      topic: topicId,
    };

    const response = await axiosClient.get(url, { params });

    return response.data;
  },

  async fetchMessages(orgId, _params) {
    const url = `/organization/${orgId}/feed/messages`;
    // { filter: filters, amount, before, after, around, findstr },
    const params = {
      ..._params,
      v2: 1,
    };

    const response = await axiosClient.get(url, { params });

    const messages = response.data;

    forEach(messages, function transformMessage(message) {
      setTemplateType(message);

      const attachments = message.attachments;

      if (attachments?.length) {
        message.attachments = attachments.map(cleanAttachment);
      }
    });

    return messages;

    function cleanAttachment(attachment) {
      return pick(attachment, ["id", "name", "extension", "mime", "size", "thumb.url", "preview.url"]);
    }
  },

  async fetchFeedState({ orgId }) {
    const url = `/organization/${orgId}/feed/state`;

    const params = {
      topic: store.getters["Organizations/ActiveOne/getShowTopics"] ? "" : "00000000-0000-0000-0000-000000000000",
    };

    const response = await axiosClient.get(url, { params });

    return response.data;
  },

  async fetchOrgTopics(orgId) {
    const url = `/organization/${orgId}/feed/topic`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchAndUpdateUnreadInfo(orgId, topicId, msgId) {
    const client = await this.updateUnreadState(orgId, topicId, msgId);

    return {
      client,
    };
  },

  async fetchUnreadByClientInfo(orgId, topicId, threadId) {
    const threadUrl = `/organization/${orgId}/feed/thread/unread/client`;
    const messagesUrl = `/organization/${orgId}/feed/messages/unread/client`;

    const url = threadId ? threadUrl : messagesUrl;
    const params = {
      topic: topicId,
      thread: threadId,
      v2: 1,
    };

    const response = await axiosClient.get(url, { params });

    return response.data;
  },

  async fetchThreadReadAllMessages(orgId, topicId, threadId) {
    const url = `/organization/${orgId}/feed/thread/unread/client`;
    const params = {
      thread: threadId,
      is_manual: false,
      v2: 1,
    };
    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.put(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    return response.data;
  },

  async fetchUnreadByOutsourcerInfo(orgId, topicId) {
    const url = `/organization/${orgId}/feed/messages/unread/outsourcer`;
    const params = {
      topic: topicId,
      v2: 1,
    };

    const response = await axiosClient.get(url, { params });

    return response.data;
  },

  async updateUnreadState(orgId, topicId, messageId, threadId) {
    const url = `/organization/${orgId}/feed/messages/${messageId}/read`;
    const params = {
      topic: topicId,
      thread: threadId,
      v2: 1,
    };
    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.put(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    return response.data;
  },

  async readAllMessages(orgId, topicId, threadId) {
    const threadUrl = `/organization/${orgId}/feed/thread/unread/client`;
    const messagesUrl = `/organization/${orgId}/feed/messages/read`;
    const url = threadId ? threadUrl : messagesUrl;

    const params = {
      topic: topicId,
      thread: threadId,
      v2: 1,
    };
    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.put(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    return response.data;
  },

  async fetchTopicsList() {
    const url = `/feed/topic`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async sendMessage(orgId, topicId, message, threadId) {
    const url = `/organization/${orgId}/feed/messages`;
    const params = {
      topic: topicId,
      v2: 1,
    };

    if (threadId) {
      params.thread = String(threadId);
    }

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, message, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    return response.data;
  },

  async sendAssignmentRequest(orgId, topicId, message) {
    const url = `/organization/${orgId}/feed/assignment/request`;
    const params = {
      topic: topicId,
      v2: 1,
    };
    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, message, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    return response.data;
  },

  async changeApprovalStatus(orgId, messageId, status) {
    const url = `/organization/${orgId}/feed/messages/${messageId}/approvalStatus`;
    const params = {
      v2: 1,
    };
    const csrf = await AuthService.fetchCSRF();
    const body = {
      messageApprovalStatus: status,
    };

    const response = await axiosClient.put(url, body, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    const message = response.data;

    message.template = "basic";

    return message;
  },

  async deleteAssignmentConfirmation(orgId, messageId) {
    const url = `/organization/${orgId}/feed/assignment/${messageId}/confirm`;
    const params = {
      v2: 1,
    };
    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.delete(url, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    const assignment = response.data;

    assignment.template = "basic";

    return assignment;
  },

  async addAssignmentConfirmation(orgId, messageId) {
    const url = `/organization/${orgId}/feed/assignment/${messageId}/confirm`;
    const params = {
      v2: 1,
    };
    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    const assignment = response.data;

    assignment.template = "basic";

    return assignment;
  },

  async fetchOrganizationInfo(orgId) {
    const url = `/organization/${orgId}`;

    const response = await axiosClient.get(url, {});

    return response.data;
  },

  async destroyMessage({ orgId, id }) {
    const url = `/organization/${orgId}/feed/messages/${id}`;

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.delete(url, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response;
  },

  async changeMessage({ orgId, id, text }) {
    const url = `/organization/${orgId}/feed/messages/${id}`;

    const params = {
      text,
    };

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.put(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    return response.data;
  },
};

export { FeedService };
