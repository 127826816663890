<template>
  <FadeTransition>
    <AppModal v-if="isOpened" :max-width="400" @close="close(false)">
      <template #header>Вы точно хотите отправить анкету?</template>

      <template #footer>
        <div class="modal__buttons">
          <div class="modal__cancel" @click="close(false)">Нет</div>

          <ButtonWithLoader :is-loading="isSending" class="modal__send" @click="send"> Да </ButtonWithLoader>
        </div>
      </template>
    </AppModal>
  </FadeTransition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FadeTransition from "@/components/transitions/Fade.vue";
import AppModal from "@/components/common/Modal.vue";
import ButtonWithLoader from "@/components/common/ButtonWithLoader.vue";

export default {
  name: "ConfirmModal",

  components: {
    FadeTransition,
    AppModal,
    ButtonWithLoader,
  },

  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    questionnaireFor1c: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isSending: false,
    };
  },

  computed: {
    ...mapGetters({ orgId: "Organizations/ActiveOne/getId" }),
  },

  methods: {
    ...mapActions({
      sendQuestionnaire: "TechAudit/sendQuestionnaire",
    }),

    close(result) {
      this.$emit("close", result);
    },
    async send() {
      try {
        this.isSending = true;

        await this.sendQuestionnaire({
          orgId: this.orgId,
          answers: this.questionnaireFor1c,
        });

        this.isSending = false;
        this.close(true);
      } catch (error) {
        this.$notify({
          type: "error",
          text: error.message,
          duration: -1,
        });

        this.isSending = false;
        this.close(false);
        throw new Error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-right: 16px;
  }

  &__cancel,
  &__send {
    display: flex;
    width: 136px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__cancel {
    margin-right: 8px;
    color: $grey-700;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      color: $grey-800;
    }
  }

  &__send {
    width: 136px;
  }
}
</style>
