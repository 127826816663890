import { render, staticRenderFns } from "./GeneralInfoBlock.vue?vue&type=template&id=389442af&scoped=true&"
import script from "./GeneralInfoBlock.vue?vue&type=script&lang=js&"
export * from "./GeneralInfoBlock.vue?vue&type=script&lang=js&"
import style0 from "./GeneralInfoBlock.vue?vue&type=style&index=0&id=389442af&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389442af",
  null
  
)

export default component.exports