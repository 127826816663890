import last from "lodash-es/last";
import head from "lodash-es/head";
import isEqual from "lodash-es/isEqual";
import property from "lodash-es/property";
import take from "lodash-es/take";
import takeRight from "lodash-es/takeRight";

import { FeedService } from "../../services";

import { CHUNK_SIZE } from "../../utils/constants";

let lastOrgId;
let lastTopicId;
let lastType;

function resetState() {
  return {
    menuData: undefined,

    attachments: [],
    attachments_are_loading: false,

    selected_type: undefined,

    frame_is_at_newest_edge: true,
    frame_is_at_oldest_edge: false,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    menuData({ menuData }) {
      return menuData;
    },

    getAttachments({ attachments }) {
      return attachments.slice();
    },

    areLoading({ attachments_are_loading }) {
      return attachments_are_loading;
    },

    getLastId({ attachments }) {
      return last(attachments.map(property("id")));
    },

    getHeadId({ attachments }) {
      return head(attachments.map(property("id")));
    },

    getSelectedType({ selected_type }) {
      return selected_type || "all";
    },

    areEmpty({ menuData }) {
      return isEqual(menuData, MENU_DATA_FOR_EMPTY_ATTACHMENTS);
    },

    getFrameIsAtNewestEdge({ frame_is_at_newest_edge }) {
      return frame_is_at_newest_edge;
    },

    getFrameIsAtOldestEdge({ frame_is_at_oldest_edge }) {
      return frame_is_at_oldest_edge;
    },
  },

  mutations: {
    SET_MENU_DATA(state, menuData) {
      state.menuData = menuData;
    },

    SET_ATTACHMENTS(state, attachments) {
      state.attachments = attachments;
    },

    SET_ATTACHMENTS_ARE_LOADING(state, value) {
      state.attachments_are_loading = value;
    },

    SET_SELECTED_TYPE(state, type) {
      state.selected_type = type;
    },

    SET_FRAME_IS_AT_NEWEST_EDGE(state, value) {
      state.frame_is_at_newest_edge = value;
    },

    SET_FRAME_IS_AT_OLDEST_EDGE(state, value) {
      state.frame_is_at_oldest_edge = value;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    selectType({ commit }, type) {
      commit("SET_SELECTED_TYPE", type);
      commit("SET_FRAME_IS_AT_NEWEST_EDGE", true);
      commit("SET_FRAME_IS_AT_OLDEST_EDGE", false);
    },

    resetMenuData({ commit }) {
      commit("SET_MENU_DATA", undefined);
    },

    resetAttachments({ commit }) {
      commit("SET_ATTACHMENTS", []);
    },

    async loadMenuData({ commit }, { orgId, topicId }) {
      lastOrgId = orgId;
      lastTopicId = topicId;

      const menuData = await FeedService.fetchAttachmentsInfo({
        orgId,
        topicId,
      });

      if (orgId != lastOrgId) return;
      if (topicId != lastTopicId) return;

      commit("SET_MENU_DATA", menuData);
    },

    async loadAttachments({ commit }, { orgId, topicId, type }) {
      lastOrgId = orgId;
      lastTopicId = topicId;
      lastType = type;

      commit("SET_ATTACHMENTS_ARE_LOADING", true);

      try {
        const attachments = await FeedService.fetchAttachments(orgId, type, {
          topic: topicId,
          amount: CHUNK_SIZE,
        });

        if (orgId != lastOrgId) return;
        if (topicId != lastTopicId) return;
        if (type != lastType) return;

        commit("SET_ATTACHMENTS", attachments);
      } finally {
        commit("SET_ATTACHMENTS_ARE_LOADING", false);
      }
    },

    async loadAttachmentsBefore({ commit, getters }, { orgId, topicId, type, before }) {
      lastOrgId = orgId;
      lastTopicId = topicId;
      lastType = type;

      let next_attachments = getters["getAttachments"];

      commit("SET_ATTACHMENTS_ARE_LOADING", true);

      try {
        const attachments = await FeedService.fetchAttachments(orgId, type, {
          topic: topicId,
          before,
          amount: CHUNK_SIZE,
        });

        if (orgId != lastOrgId) return;
        if (topicId != lastTopicId) return;
        if (type != lastType) return;

        next_attachments = next_attachments.concat(attachments);

        if (attachments.length < CHUNK_SIZE) {
          commit("SET_FRAME_IS_AT_OLDEST_EDGE", true);
        } else {
          if (next_attachments.length > 4 * CHUNK_SIZE) {
            next_attachments = takeRight(next_attachments, 2 * CHUNK_SIZE);
            commit("SET_FRAME_IS_AT_NEWEST_EDGE", false);
          }
        }

        commit("SET_ATTACHMENTS", next_attachments);

        commit("SET_ATTACHMENTS_ARE_LOADING", false);
      } catch (error) {
        console.error("Sidebar attachments loadAttachmentsBefore:", error);
      }
    },

    async loadAttachmentsAfter({ commit, getters }, { orgId, topicId, type, after }) {
      lastOrgId = orgId;
      lastTopicId = topicId;
      lastType = type;

      let next_attachments = getters["getAttachments"];

      commit("SET_ATTACHMENTS_ARE_LOADING", true);

      try {
        const attachments = await FeedService.fetchAttachments(orgId, type, {
          topic: topicId,
          after,
          amount: CHUNK_SIZE,
        });

        if (orgId != lastOrgId) return;
        if (topicId != lastTopicId) return;
        if (type != lastType) return;

        next_attachments = attachments.concat(next_attachments);

        if (attachments.length < CHUNK_SIZE) {
          commit("SET_FRAME_IS_AT_NEWEST_EDGE", true);
        } else {
          if (next_attachments.length > 4 * CHUNK_SIZE) {
            next_attachments = take(next_attachments, 2 * CHUNK_SIZE);
            commit("SET_FRAME_IS_AT_OLDEST_EDGE", false);
          }
        }

        commit("SET_ATTACHMENTS", next_attachments);

        commit("SET_ATTACHMENTS_ARE_LOADING", false);
      } catch (error) {
        console.error("Sidebar attachments loadAttachmentsAfter:", error);
      }
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};

const MENU_DATA_FOR_EMPTY_ATTACHMENTS = {
  document: 0,
  image: 0,
  archive: 0,
  audio: 0,
  video: 0,
  other: 0,
  link: 0,
  recognition: 0,
};
