import * as linkify from "linkifyjs";

linkify.registerCustomProtocol("mention");

linkify.registerPlugin("mention", ({ scanner, parser, utils }) => {
  const { OPENANGLEBRACKET, CLOSEANGLEBRACKET, AT, DOMAIN, TLD } = scanner.tokens;

  let STATE = parser.start;
  const Mention = utils.createTokenClass("mention");

  STATE = STATE.tt(OPENANGLEBRACKET);
  STATE = STATE.tt(AT);

  let DOMAIN_STATE = STATE.tt(DOMAIN);
  // have to use the TLD to make it possible
  // to tokenize top level domain 'here' for our inner heeds
  let TLD_STATE = STATE.tt(TLD);

  DOMAIN_STATE.tt(CLOSEANGLEBRACKET, Mention);
  TLD_STATE.tt(CLOSEANGLEBRACKET, Mention);
});
