<template>
  <div class="cab-tooltip">
    <div ref="activator" class="cab-tooltip__activator" @mouseenter="show" @mouseleave="hideWithTimeout">
      <slot name="activator"></slot>
    </div>

    <div
      ref="body"
      class="cab-tooltip__body"
      :class="{ hidden: !showBody }"
      @mouseenter="show"
      @mouseleave="hideWithTimeout"
    >
      <div v-if="interactive" class="cab-tooltip__interactive"></div>

      <div class="cab-tooltip__arrow" data-popper-arrow></div>

      <div class="cab-tooltip__continer">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "CabTooltip",

  props: {
    boundary: {
      type: HTMLElement,
      default: undefined,
    },

    placement: {
      type: String,
      required: true,
    },

    timeout: {
      type: Number,
      default: 250,
    },

    showBody: {
      type: Boolean,
      default: true,
    },

    interactive: {
      type: Boolean,
      default: false,
    },
  },

  // TODO: set an offset for a particular placement if you need
  OFFSET_FOR_PLACEMENT: {
    // auto: [8, 8],
    // "auto-start": [8, 8],
    // "auto-end": [8, 8],
    top: [0, 8],
    // "top-start": [8, 8],
    // "top-end": [8, 8],
    bottom: [0, 8],
    "bottom-start": [0, 8],
    "bottom-end": [18, 10],
    // right: [8, 8],
    "right-start": [-8, 8],
    "right-end": [8, 8],
    // left: [8, 8],
    "left-start": [-8, 8],
    "left-end": [8, 8],
  },

  data() {
    return {
      popperInstance: undefined,
      timeoutId: undefined,
    };
  },

  mounted() {
    this.popperInstance = createPopper(this.$refs["activator"], this.$refs["body"], {
      placement: this.placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: this.$options.OFFSET_FOR_PLACEMENT[this.placement],
          },
        },
        {
          name: "preventOverflow",
          options: {
            boundary: this.boundary,
          },
        },
        { name: "eventListeners", enabled: false },
      ],
    });
  },

  beforeDestroy() {
    this.popperInstance.destroy();
  },

  methods: {
    show() {
      clearTimeout(this.timeoutId);

      // Make the tooltip visible
      this.$refs["body"].setAttribute("data-show", "");

      this.popperInstance.update();

      setTimeout(updatePopperOptions.bind(this), 0);

      function updatePopperOptions() {
        this.popperInstance.setOptions((options) => {
          const currPlacement = this.popperInstance.state.placement;

          return {
            ...options,
            modifiers: [
              ...options.modifiers,
              {
                name: "offset",
                options: {
                  offset: this.$options.OFFSET_FOR_PLACEMENT[currPlacement],
                },
              },
              { name: "eventListeners", enabled: true },
            ],
          };
        });
      }
    },

    hideWithTimeout() {
      this.timeoutId = setTimeout(this.hide, this.timeout);
    },

    hide() {
      // Hide the tooltip
      this.$refs["body"].removeAttribute("data-show");

      // Disable the event listeners
      this.popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [...options.modifiers, { name: "eventListeners", enabled: false }],
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.cab-tooltip {
  position: relative;
  display: inline-block;

  &__activator {
    display: flex;
    cursor: pointer;
  }

  &__body {
    z-index: 2;
    display: none;
    border-radius: 6px;
    background: white;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    font-weight: 400;
  }

  &__body[data-show] {
    display: block;
  }

  &__body[data-show].hidden {
    display: none;
  }

  &__arrow {
    position: absolute;
    right: -10px;
    width: 8px;
    height: 8px;
    visibility: hidden;
  }

  &__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: white;
    content: "";
    transform: rotate(45deg);
    visibility: visible;
  }

  &__continer {
    padding: 12px;
    line-height: 1.4;
  }

  &__interactive {
    position: absolute;
    z-index: -1;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: transparent;
  }
}

[data-popper-placement^="top"] > .cab-tooltip__arrow {
  bottom: -4px;
}

[data-popper-placement^="bottom"] > .cab-tooltip__arrow {
  top: -4px;
}

[data-popper-placement^="left"] > .cab-tooltip__arrow {
  right: 4px;
}

[data-popper-placement^="right"] > .cab-tooltip__arrow {
  left: -4px;
}

[data-popper-placement$="end"] > .cab-tooltip__arrow {
  // left: -4px !important;
}
</style>
