<template>
  <div class="contract-history">
    <slot name="title"></slot>

    <div v-if="isLoading" class="contract-history__loader-overlay">
      <img class="contract-history__loader-icon" src="@icons/bubble-loader.svg" width="40" />
    </div>

    <template v-else>
      <div v-for="(item, index) in history" :key="item.org_id + index" class="contract-history-item">
        <div class="contract-history-item__wrapper">
          <AppIcon :src="icon(item.action)" class="contract-history-item__icon" />
          <div class="contract-history-item__dashed-line"></div>
        </div>
        <div class="contract-history-item__text">
          <div
            class="contract-history-item__title"
            :class="{
              'contract-history-item__title--bold': actions[item.action]?.is_bold,
            }"
          >
            {{ item.text }}
          </div>
          <div class="contract-history-item__date">
            {{ item.date | localDate }}
          </div>
        </div>
        <FileDownloadIcon
          :file-id="item?.file?.id"
          :org-id="item?.org_id"
          :form="item?.form"
          :action="item?.action"
          class="contract-history-item__link"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Vue, { computed } from "vue";

import { useQuery } from "@tanstack/vue-query";

import store from "../../store";

import { InfoService } from "../../services";

import AppIcon from "@/components/common/Icon.vue";
import FileDownloadIcon from "../common/FileDownloadIcon.vue";

export default {
  name: "TariffContractHistory",

  components: {
    AppIcon,
    FileDownloadIcon,
  },

  setup() {
    const orgId = computed(() => store.getters["Organizations/ActiveOne/getId"]);

    const { data: history, isLoading } = useQuery(
      ["contractHistory", orgId],
      () => InfoService.fetchContractHistory(orgId.value),
      {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false,
        onError: () => {
          Vue.notify({
            type: "error",
            text: "Ошибка при получении истории контракта",
          });
        },
      }
    );

    return { history, isLoading };
  },

  data() {
    return {
      actions: {
        deposit_received: {
          icon: "@icons/raw-svg/contract-history/deposit.svg", // Получен гарантийный взнос
          is_bold: true,
        },
        contract_concluded: {
          icon: "@icons/raw-svg/contract-history/accepted.svg", // Договор заключен
          is_bold: true,
        },
        contract_suspended: {
          icon: "@icons/raw-svg/contract-history/pause.svg", // Приостановка договора
          is_bold: true,
        },
        contract_renewal: {
          icon: "@icons/raw-svg/contract-history/renewed.svg", // Возобновление действия договора
          is_bold: true,
        },
        contract_termination: {
          icon: "@icons/raw-svg/contract-history/terminated.svg", // Прекращение действия договора
          is_bold: true,
        },
        changed_interactions_regulations: {
          icon: "@icons/raw-svg/contract-history/documents.svg", // Изменение регламента взаимодействия
        },
        changed_representative: {
          icon: "@icons/raw-svg/contract-history/documents.svg", // Изменение полномочий представителя
        },
        changed_interactions_schedule: {
          icon: "@icons/raw-svg/contract-history/documents.svg", // Изменение графика взаимодействия
        },
        added_pricelist: {
          icon: "@icons/raw-svg/contract-history/price.svg", // Добавлен прайс-лист
        },
        changed_pricelist: {
          icon: "@icons/raw-svg/contract-history/price.svg", // Изменение прайс-листа
        },
        ///////////////////////////////
        // 44444: "@icons/raw-svg/contract-history/users.svg", // Добавлены представители
        // 55555: "@icons/raw-svg/contract-history/bill_downloaded.svg", // Счёт загружен
        // 66666: "@icons/raw-svg/contract-history/pay_report_sent.svg", // Платёжка отправлена
        // changed_tariff: "11111", // Изменение тарифа
        // changed_tariff_conditions: "22222", // Изменение условий тарифа
      },
    };
  },

  methods: {
    icon(action) {
      return this.actions[action]?.icon || "@icons/raw-svg/contract-history/documents.svg";
    },
  },
};
</script>

// TODO: make scoped
<style lang="scss">
.contract-history {
  &__title {
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 500;
  }

  // TODO: fix, this class is used outside this component
  &__loader-overlay {
    display: flex;
    width: 100%;
    height: 300px;
  }

  &__loader-icon {
    display: block;
    margin: auto;
  }

  @media (max-width: 960px) {
    &__title {
      font-size: 20px;
    }
  }

  @media (max-width: 600px) {
    &__title {
      font-size: 18px;
    }
  }
}

.contract-history-item {
  position: relative;
  display: flex;

  &__icon {
    display: flex;
    width: 44px;
    height: 44px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-300;
    border-radius: 50%;
    margin-right: 24px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &__dashed-line {
    width: 1px;
    height: calc(100% - 40px);
    margin-left: 22px;
    background: url("../../assets/icons/contract-history/dashed-line.svg");
    background-position-y: -2px;

    :last-child > * > & {
      display: none;
    }
  }

  &__text {
    display: flex;
    min-height: 40px;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 6px;

    &--bold {
      font-weight: 600;
    }
  }

  &__date {
    color: $grey-600;
    font-size: 12px;
  }

  &__link {
    display: flex;
    width: 40px;
    height: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 5px;
    margin-left: auto;
    background: $grey-50;
    color: $grey-600;
    transition: $shortest;

    &:hover {
      background: $grey-100;
      color: $grey-700;
    }
  }
}
</style>
