<template>
  <label :class="{ 'input-radio--disabled': disabled }" class="input-radio">
    <input
      type="radio"
      :checked="isChecked"
      :value="value"
      :required="required"
      :name="name"
      :disabled="disabled"
      class="input-radio__input"
      @change="updateValue($event.target.checked)"
    />
    <div :class="{ 'input-radio__radio--checked': isChecked }" class="input-radio__radio"></div>
    <div class="input-radio__text">
      <slot></slot>
    </div>
  </label>
</template>

<script>
export default {
  name: "InputRadio",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    modelValue: {
      type: [String, Boolean, Number],
      default: null,
    },
    value: {
      type: [String, Boolean, Number],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value && this.value !== "";
    },
  },
  methods: {
    updateValue(isChecked) {
      if (isChecked) {
        this.$emit("change", this.value);
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* stylelint-disable selector-class-pattern */
.input-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input-radio--disabled {
  cursor: default;

  & * {
    filter: grayscale(1);
    opacity: 0.8;
  }
}

.input-radio__input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.input-radio__radio {
  display: flex;
  width: 18px;
  height: 18px;
  flex: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #c6c6c6;
  border-radius: 100%;
  margin-right: 12px;
}

.input-radio__radio--checked {
  border: none;
  background: $yellow-500;

  &::before {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: $grey-800;
    content: "";
  }
}
</style>
