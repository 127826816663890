<template>
  <div class="select">
    <div class="select__selected-option" :class="classesForSelectedOption" @click="showOptions">
      <input v-model="hours" v-mask="hoursMask" type="text" class="select__input" @change="onChange" />
      :
      <input v-model="minutes" v-mask="minutesMask" type="text" class="select__input" @change="onChange" />
    </div>

    <div ref="target" class="select__options" :class="{ 'select__options--opened': isOpened }">
      <div
        v-for="key in optionKeys"
        :key="key"
        class="select__option"
        :class="classesForOption(key)"
        @click="selectOption(+key)"
      >
        {{ options[key] }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { onClickOutside } from "@vueuse/core";

import { VueMaskDirective } from "v-mask";

function hoursMask(value) {
  return [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
}

export default {
  name: "SelectTime",

  directives: {
    mask: VueMaskDirective,
  },

  model: {
    prop: "minutesTotal",
    event: "change",
  },

  props: {
    minutesTotal: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 23 * 60 + 59; // 00:00 - 23:59
      },
    },
  },

  setup() {
    const target = ref(null);
    const isOpened = ref(false);

    onClickOutside(target, function handler() {
      if (isOpened.value) isOpened.value = false;
    });

    return { target, isOpened };
  },

  data() {
    return {
      hours: 0,
      minutes: 0,

      hoursMask,
      minutesMask: [/[0-5]/, /[0-9]/],
    };
  },

  computed: {
    options() {
      const result = {};

      // 30 minutes step
      for (let i = 0; i < 48; i++) {
        result[i * 30] = new Date(0, 0, 0, 0, i * 30).toLocaleTimeString("ru").slice(0, 5);
      }

      return result;
    },

    optionKeys() {
      return Object.keys(this.options);
    },

    classesForSelectedOption() {
      return {
        "select__selected-option--opened": this.isOpened,
      };
    },
  },

  watch: {
    minutesTotal: {
      handler(value) {
        let minutes = value % 60;
        let hours = (value - minutes) / 60;

        this.hours = `${hours}`.padStart(2, "0");
        this.minutes = `${minutes}`.padStart(2, "0");
      },

      immediate: true,
    },

    hours() {
      this.hideOptions();
    },

    minutes() {
      this.hideOptions();
    },
  },

  methods: {
    showOptions() {
      this.isOpened = true;
    },

    hideOptions() {
      this.isOpened = false;
    },

    classesForOption(value) {
      return {
        "select__option--selected": this.timeInMinutes == value,
      };
    },

    onChange() {
      this.$emit("change", +this.hours * 60 + +this.minutes);
    },

    selectOption(value) {
      this.$emit("change", value);

      this.hideOptions();
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;

  &__selected-option {
    display: flex;
    height: 24px;
    align-items: center;
    border-bottom: 1px solid $grey-300;
    cursor: pointer;
    font-size: 14px;
  }

  &__input {
    width: 18px;
    outline: none;

    &:last-child {
      width: 20px;
      padding-left: 2px;
    }
  }

  &__options {
    position: absolute;
    z-index: 1;
    overflow: auto;
    max-width: 174px;
    max-height: 130px;
    padding: 6px 0;
    border-radius: 6px;
    background: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    opacity: 0;
    pointer-events: none;
    scrollbar-width: thin;
    transform: translateY(30px);
    transition: opacity $shortest;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $grey-200;
    }

    &--opened {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      background: $grey-100;
    }

    &--selected {
      font-weight: 700;
    }

    &--forbidden {
      color: $grey-600;
      pointer-events: none;
    }
  }
}
</style>
