<template>
  <notifications position="bottom right" :duration="3000" width="none" :max="3">
    <template slot="body" slot-scope="{ item, close }">
      <div class="notification" @click="close">
        <div class="notification__left">
          <img
            v-if="item.type == 'reminder-create'"
            class="notification__icon"
            src="@icons/notifications/reminder-create.svg"
          />
          <img
            v-else-if="item.type == 'reminder-delete'"
            class="notification__icon"
            src="@icons/notifications/reminder-delete.svg"
          />
          <img v-else-if="item.type == 'error'" class="notification__icon" src="@icons/notifications/error.svg" />
          <img v-else class="notification__icon" src="@icons/notifications/success.svg" />
        </div>

        <div class="notification__right">
          <div class="notification__title">
            {{ item.title }}
          </div>

          <div v-if="item.text" class="notification__text" v-html="item.text" />
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: "AppNotifications",
};
</script>

<style lang="scss">
.notification {
  display: flex;
  padding: 20px;
  cursor: pointer;

  &__left {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__icon {
    width: 30px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__title {
    font-weight: 500;
    line-height: 1;
  }

  &__link {
    color: $blue-500;
    font-size: 14px;
    transition: color $shortest;

    &:hover {
      color: darken($blue-500, 20%);
    }
  }
}

.vue-notification-group {
  right: 48px !important;
  bottom: 48px !important;

  .vue-notification-wrapper {
    width: fit-content;
    min-height: 70px;
    border-radius: 6px;
    margin-bottom: 16px;
    margin-left: auto;
    background: white;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1264px) {
    right: 42px !important;
    bottom: 42px !important;
  }

  @media (max-width: 960px) {
    right: 36px !important;
    bottom: 36px !important;
  }

  @media (max-width: 600px) {
    right: 16px !important;
    bottom: 16px !important;
    margin-left: 16px;
  }
}
</style>
