<template>
  <div class="block">
    <div class="block__faux-input faux-input">
      <div class="faux-input__label">Имя</div>

      <div class="faux-input__value">{{ name }}</div>

      <img class="faux-input__icon" src="@icons/edit.svg" @click="openNameModal" />
    </div>

    <div class="block__faux-input faux-input">
      <div class="faux-input__label">Должность</div>

      <div class="faux-input__value">{{ position }}</div>

      <img class="faux-input__icon" src="@icons/edit.svg" @click="openPositionModal" />
    </div>

    <div class="block__faux-input faux-input">
      <div class="faux-input__label">Адрес электронной почты</div>

      <div class="faux-input__value">{{ email }}</div>

      <img class="faux-input__icon" src="@icons/edit.svg" @click="openEmailModal" />
    </div>

    <div class="block__faux-input faux-input">
      <div class="faux-input__label">Номер телефона</div>

      <div class="faux-input__value">{{ phone }}</div>

      <img class="faux-input__icon" src="@icons/edit.svg" @click="openPhoneModal" />
    </div>

    <FadeTransition
      ><AppModal v-if="openedModal == 'name'" :max-width="360" @close="closeModal">
        <template #header>Изменение имени</template>

        <template #body>
          <CabTextField
            v-model.trim="newName"
            class="modal__input"
            label="Новое имя"
            name="change-user-name"
            placeholder=" Введите имя"
            :error-message="nameErrorMessage"
            @input="nameErrorMessage = ''"
          />
        </template>

        <template #footer>
          <div class="modal__buttons">
            <div class="filler"></div>

            <div class="modal__cancel" @click="closeModal">Отмена</div>

            <ButtonWithLoader :is-blocked="!$v.newName.required" class="modal__save" @click="onClick('name')">
              Изменить
            </ButtonWithLoader>
          </div>
        </template>
      </AppModal>

      <AppModal v-if="openedModal == 'position'" :max-width="360" @close="closeModal">
        <template #header>Изменение должности</template>

        <template #body>
          <CabTextField
            v-model.trim="newPosition"
            class="modal__input"
            label="Новая должность"
            name="change-user-name"
            placeholder=" Введите должность"
            :error-message="positionErrorMessage"
            @input="positionErrorMessage = ''"
          />
        </template>

        <template #footer>
          <div class="modal__buttons">
            <div class="filler"></div>

            <div class="modal__cancel" @click="closeModal">Отмена</div>

            <ButtonWithLoader :is-blocked="!$v.newPosition.required" class="modal__save" @click="onClick('position')">
              Изменить
            </ButtonWithLoader>
          </div>
        </template>
      </AppModal>

      <AppModal v-if="openedModal == 'email'" :max-width="360" @close="closeModal">
        <template #header>Изменение E-mail</template>

        <template #body>
          <CabTextField
            v-model.trim="newEmail"
            class="modal__input"
            label="Новый E-mail"
            name="change-user-name"
            placeholder=" Введите E-mail"
            :error-message="emailErrorMessage"
            @input="emailErrorMessage = ''"
          />
        </template>

        <template #footer>
          <div class="modal__buttons">
            <div class="filler"></div>

            <div class="modal__cancel" @click="closeModal">Отмена</div>

            <ButtonWithLoader :is-blocked="!$v.newEmail.required" class="modal__save" @click="onClick('email')">
              Изменить
            </ButtonWithLoader>
          </div>
        </template>
      </AppModal>

      <AppModal v-if="openedModal == 'phone'" :max-width="360" @close="closeModal">
        <template #header>Изменение номера телефона</template>

        <template #body>
          <CabTextField
            v-model.trim="newPhone"
            class="modal__input"
            label="Новый номер телефона"
            name="change-user-name"
            placeholder=" Введите номер телефона"
            :error-message="phoneErrorMessage"
            @input="phoneErrorMessage = ''"
          />
        </template>

        <template #footer>
          <div class="modal__buttons">
            <div class="filler"></div>

            <div class="modal__cancel" @click="closeModal">Отмена</div>

            <ButtonWithLoader :is-blocked="!$v.newPhone.required" class="modal__save" @click="onClick('phone')">
              Изменить
            </ButtonWithLoader>
          </div>
        </template>
      </AppModal>
    </FadeTransition>
  </div>
</template>

<script>
import { required, email, maxLength } from "vuelidate/dist/validators.min";

import { mapGetters, mapActions } from "vuex";

import FadeTransition from "../transitions/Fade.vue";
import AppModal from "../common/Modal.vue";
import CabTextField from "../common/CabTextField.vue";
import ButtonWithLoader from "../common/ButtonWithLoader.vue";

export default {
  name: "UserSettingsGeneralInfoBlock",

  components: {
    FadeTransition,
    AppModal,
    CabTextField,
    ButtonWithLoader,
  },

  data() {
    return {
      openedModal: "",

      newName: "",
      newPosition: "",
      newEmail: "",
      newPhone: "",

      nameErrorMessage: "",
      positionErrorMessage: "",
      emailErrorMessage: "",
      phoneErrorMessage: "",
    };
  },

  validations: {
    newName: {
      required,
      maxLength: maxLength(150),
    },

    newPosition: {
      required,
      maxLength: maxLength(100),
    },

    newEmail: {
      required,
      email,
      maxLength: maxLength(72),
    },

    newPhone: {
      required,
      maxLength: maxLength(500),
    },
  },

  computed: {
    ...mapGetters({
      name: "User/getName",
      position: "User/getPosition",
      email: "User/getEmail",
      phone: "User/getPhone",
    }),
  },

  methods: {
    ...mapActions({
      changeField: "User/Settings/changeField",
    }),

    async onClick(fieldName) {
      if (fieldName == "name") {
        if (!this.$v.newName.maxLength) {
          this.nameErrorMessage = "Максимально допустимое количество символов: 150";

          return;
        }

        try {
          await this.changeField({ fieldName, value: this.newName });

          this.$notify({
            text: `Вы успешно изменили имя на ${this.newName}`,
          });
        } catch {
          this.$notify({
            type: "error",
            text: "Ошибка при смене имени",
          });
        }

        this.openedModal = "";
      } else if (fieldName == "position") {
        if (!this.$v.newPosition.maxLength) {
          this.positionErrorMessage = "Максимально допустимое количество символов: 100";

          return;
        }

        try {
          await this.changeField({ fieldName, value: this.newPosition });

          this.$notify({
            text: `Вы успешно изменили должность на ${this.newPosition}`,
          });
        } catch {
          this.$notify({
            type: "error",
            text: "Ошибка при смене должности",
          });
        }

        this.openedModal = "";
      } else if (fieldName == "email") {
        if (!this.$v.newEmail.email) {
          this.emailErrorMessage = "Неверный формат электронной почты";

          return;
        }

        if (!this.$v.newEmail.maxLength) {
          this.emailErrorMessage = "Максимально допустимое количество символов: 72";

          return;
        }

        try {
          await this.changeField({ fieldName, value: this.newEmail });

          this.$notify({
            text: `Вы успешно изменили электронную почту на ${this.newEmail}`,
          });
        } catch (error) {
          // TODO: uniformly handle errors in all forms
          if (error.message == "Такой адрес электронной почты уже занят.") {
            this.emailErrorMessage = "Такой адрес электронной почты уже занят.";
            return;
          } else {
            this.$notify({
              type: "error",
              text: "Ошибка при смене электронной почты",
            });
          }
        }

        this.openedModal = "";
      } else if (fieldName == "phone") {
        if (!this.$v.newPhone.maxLength) {
          this.phoneErrorMessage = "Максимально допустимое количество символов: 500";

          return;
        }

        try {
          await this.changeField({ fieldName, value: this.newPhone });

          this.$notify({
            text: `Вы успешно изменили номер телефона на ${this.newPhone}`,
          });
        } catch {
          this.$notify({
            type: "error",
            text: "Ошибка при смене номера телефона",
          });
        }

        this.openedModal = "";
      }
    },

    openNameModal() {
      this.newName = this.name;
      this.openedModal = "name";
    },

    openPositionModal() {
      this.newPosition = this.position;
      this.openedModal = "position";
    },

    openEmailModal() {
      this.newEmail = this.email;
      this.openedModal = "email";
    },

    openPhoneModal() {
      this.newPhone = this.phone;
      this.openedModal = "phone";
    },

    closeModal() {
      this.openedModal = "";

      this.nameErrorMessage = "";
      this.positionErrorMessage = "";
      this.emailErrorMessage = "";
      this.phoneErrorMessage = "";
    },
  },
};
</script>

<style scoped lang="scss">
.block {
  &__faux-input {
    &:not(:last-child) {
      margin-bottom: 36px;
    }
  }
}

.faux-input {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $grey-600;
  color: $grey-600;

  &__label {
    min-width: 100%;
    margin-bottom: 8px;
    font-size: 12px;
  }

  &__value {
    @include ellipsis;

    width: calc(100% - 16px);
    margin-bottom: 10px;
    font-size: 15px;
  }

  &__icon {
    width: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity $shortest;

    &:hover {
      opacity: 1;
    }
  }
}

.modal {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-right: 16px;
  }

  &__input {
    margin-top: 20px;
  }

  &__cancel {
    display: flex;
    width: 130px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: $grey-700;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;

    &:hover {
      color: $grey-800;
    }
  }

  &__save {
    width: 130px;
    height: 40px;
  }
}
</style>
