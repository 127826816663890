import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";

const InfoService = {
  async fetchCities() {
    const url = `/info/cities`;

    const response = await axiosClient.get(url);

    return response.data.cities;
  },

  async fetchAllUsersInDomain() {
    const url = `/users`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchTimezones() {
    const url = `/timezone/city`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchContractHistory(orgId) {
    const url = `/organization/${orgId}/contract?v2=1`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchDocuments(orgId) {
    const url = `/organization/${orgId}/documents?v2=1`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchResponsibles(orgId) {
    const url = `/organization/${orgId}/responsible`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchAgentsAndTheirRights(orgId) {
    const url = `/organization/${orgId}/agents`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async addAgent(orgId, agent) {
    let url = `/organization/${orgId}/agents`;

    const csrf = await AuthService.fetchCSRF();

    let formData = new FormData();

    for (let field in agent) formData.append(field, agent[field]);

    const response = await axiosClient.post(url, formData, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },

  async fetchDownloads() {
    const url = `/info/downloads`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchDownloadFile(url) {
    let filename = "";

    try {
      const response = await axiosClient.get(url, { responseType: "blob" });

      const disposition = response.headers.get("Content-Disposition");

      if (disposition) {
        let fileNameRus = disposition.match(/filename\*=(.+?)(;|$)/);

        fileNameRus
          ? (filename = fileNameRus[1])
          : (filename = disposition.match(/filename=(.+?)(;|$)/)[1].replace(/[-"?\s]/g, ""));

        if (filename) {
          if (filename.toLowerCase().startsWith("utf-8''")) {
            filename = decodeURIComponent(filename.replace(/utf-8''/i, ""));
          } else {
            filename = filename.replace(/['"]/g, "");
          }
        }
      }

      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.data);
      link.download = filename || "file.pdf";
      link.click();

      link.remove();

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        if (error.response.data instanceof Blob && error.response.data.type === "application/json") {
          const responseData = await error.response.data.text();

          const responseJson = JSON.parse(responseData);

          if (!responseJson.message && !responseJson.error) {
            responseJson.message = "Ошибка получения файла";
          }

          throw responseJson;
        } else {
          throw error.response.data;
        }
      } else {
        throw error;
      }
    }
  },
};

export { InfoService };
