<template>
  <div class="tab">
    <div v-if="isLoading" class="tab__loader-overlay">
      <img src="@icons/bubble-loader.svg" width="48" />
    </div>

    <div v-else-if="isError">Error...</div>

    <template v-else>
      <div class="tab__top">
        <div class="tab__top__structure">
          <SearchBlock v-if="agents.length || searchStrAgents.length" @value="(val) => (searchStrAgents = val)" />

          <AddNewAgentBlock :agents="agents" :rights="rights" class="tab__add-agent" />
        </div>

        <AgentsSlider
          v-if="agents.length"
          :agents="agents"
          :is-at-start="sliderIsAtStart"
          :is-at-end="sliderIsAtEnd"
          class="tab__slider"
          @slide-prev="slidePrev"
          @slide-next="slideNext"
        />
      </div>

      <div v-if="agents.length" class="button-manual">
        <span class="button-manual__title">Уполномоченные представители</span>
        <FileDownloadIcon
          :contract-id="rights.contract_id"
          :doc-id="rights.doc_id"
          :form-id="'agents'"
          class="button-manual__download"
        />
      </div>

      <div v-if="!agents.length && searchStrAgents.length" class="not-found-block">
        <div class="not-found-block__fon">
          <img class="not-found-block__fon__img" src="@images/raw-svg/search.svg" />
        </div>
        <span class="not-found-block__text">Нет результатов по вашему запросу</span>
      </div>

      <AgentsRightsTable
        v-if="agents.length"
        title="Сфера полномочий представителя Заказчика"
        :agents="agents"
        :rights="rights.authority"
      />

      <template v-if="showAllSections && agents.length">
        <AgentsRightsTable
          title="Права пользователей в приложении БП"
          :agents="agents"
          :rights="rights.accounting.main"
        />

        <AgentsRightsTable
          title="Дополнительные профили пользователей в приложении БП"
          :agents="agents"
          :rights="rights.accounting.extra"
        />

        <AgentsRightsTable title="Права пользователей в приложении ЗиУП" :agents="agents" :rights="rights.hrm" />

        <AgentsRightsTable title="Права пользователей в приложении УНФ" :agents="agents" :rights="rights.unf" />
      </template>
    </template>
  </div>
</template>

<script>
import Vue, { computed } from "vue";

import { useQuery } from "@tanstack/vue-query";

import { mapGetters } from "vuex";
import store from "../../store";

import { InfoService } from "../../services";

import AddNewAgentBlock from "../../components/Tariff/AddNewAgentBlock.vue";
import AgentsSlider from "../../components/Tariff/AgentsSlider.vue";
import AgentsRightsTable from "../../components/Tariff/AgentsRightsTable.vue";
import FileDownloadIcon from "@/components/common/FileDownloadIcon.vue";
import SearchBlock from "@/components/Tariff/SearchBlock.vue";

export default {
  name: "TariffResponsible",

  components: {
    SearchBlock,
    FileDownloadIcon,
    AddNewAgentBlock,
    AgentsSlider,
    AgentsRightsTable,
  },

  inject: ["screenSize"],

  setup() {
    const orgId = computed(() => store.getters["Organizations/ActiveOne/getId"]);

    const { data, isLoading, isError } = useQuery(
      ["agents", orgId],
      () => InfoService.fetchAgentsAndTheirRights(orgId.value),
      {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false,
        onError: () => {
          Vue.notify({
            type: "error",
            text: "Ошибка при загрузке представителей (agents)",
          });
        },
      }
    );

    return { data, isLoading, isError, orgId };
  },

  data() {
    return {
      startIndex: 0,
      searchStrAgents: "",
    };
  },

  computed: {
    agentsAmount() {
      if (this.screenSize.smallerOrEqual("xs").value) {
        return 2;
      } else if (this.screenSize.smallerOrEqual("md").value) {
        return 3;
      } else if (this.screenSize.smallerOrEqual("lg").value) {
        return 4;
      } else {
        return 5;
      }
    },

    agents() {
      return (this.data?.agents || [])
        .slice(this.startIndex, this.startIndex + this.agentsAmount)
        .filter((agent) => agent.name.toUpperCase().includes(this.searchStrAgents.toUpperCase()));
    },

    rights() {
      return this.data || {};
    },

    sliderIsAtStart() {
      return this.startIndex == 0;
    },

    sliderIsAtEnd() {
      const amount = this.agentsAmount;

      return this.startIndex + amount >= (this.data?.agents || []).length;
    },

    ...mapGetters("Organizations/ActiveOne", {
      tariff: "getContractTariff",
    }),

    showAllSections() {
      const FORBIDDEN_TARIFF_KEYS = ["ПрограммаИКонсультация", "НулеваяОтчетность"];

      return !FORBIDDEN_TARIFF_KEYS.includes(this.tariff.key);
    },
  },

  watch: {
    orgId() {
      this.startIndex = 0;
    },
  },

  methods: {
    slidePrev() {
      this.startIndex--;
    },

    slideNext() {
      this.startIndex++;
    },
  },
};
</script>

<style scoped lang="scss">
.tab {
  height: 100%;

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &__structure {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 50px;
    }
  }

  &__add-agent {
    width: 360px;
    padding-left: 28px;
  }

  &__slider {
    width: calc(100% - 360px);
    padding: 0 48px;
  }

  &__loader-overlay {
    display: flex;
    width: 100%;
    height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1265px) and (max-width: 1904px) {
    // linear equation:
    // 1904*x1 + x2 = 360
    // 1265*x1 + x2 = 300
    &__add-agent {
      width: calc(10vw + 180px);
    }

    &__slider {
      width: calc(100% - 10vw - 180px);
    }
  }

  @media (min-width: 961px) and (max-width: 1264px) {
    // linear equation:
    // 1264*x1 + x2 = 360
    // 961*x1 + x2 = 300
    &__add-agent {
      width: calc(20vw + 110px);
    }

    &__slider {
      width: calc(100% - 20vw - 110px);
    }
  }

  @media (max-width: 960px) {
    &__top {
      flex-direction: column;
    }

    &__add-agent {
      width: 100%;
      margin-top: 20px;
    }

    &__slider {
      width: 100%;
      padding: 32px 24px;
    }
  }

  @media (max-width: 600px) {
    &__slider {
      padding: 24px 12px;
    }

    &__add-agent {
      width: 100%;
      padding: 0 10px;
    }
  }
}

.button-manual {
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  transition: 0.3s;

  &:hover {
    background-color: #f6f6f6;
  }

  &__title {
    color: #1c1c1c;
    font-size: 18px;
  }

  &__download {
    width: 16px;
    margin-left: 5px;
    color: #848484;

    &:hover {
      color: #6b6b6b;
    }
  }
}

.not-found-block {
  display: flex;
  max-width: 312px;
  flex-direction: column;
  align-items: center;
  margin: 120px auto auto;

  &__fon {
    display: flex;
    width: 190px;
    height: 190px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f8f8f8;

    &__img {
      width: 100px;
      height: 100px;
    }
  }

  &__text {
    margin-top: 26px;
    font-size: 32px;
    text-align: center;
  }
}
</style>
