<template>
  <div class="view">
    <div class="view__main">
      <div class="view__title">Настройки аккаунта</div>

      <ProfileBlock class="view__profile-block" />

      <div class="view__columns">
        <div class="view__column view__column--1">
          <div class="view__column-title">Общая информация</div>

          <GeneralInfoBlock />
        </div>

        <div class="view__column view__column--2">
          <div class="view__column-title">Изменить пароль</div>

          <div class="view__column-subtitle">
            Пароль должен содержать цифры, прописные и строчные буквы и состоять хотя бы из 7 символов
          </div>

          <PasswordForm />
        </div>

        <div class="view__column view__column--3">
          <div class="view__column-title">Уведомления</div>

          <div class="view__column-subtitle">Разрешите получение уведомлений в браузере</div>

          <NotificationsBlock />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileBlock from "@/components/UserSettings/ProfileBlock.vue";
import GeneralInfoBlock from "@/components/UserSettings/GeneralInfoBlock.vue";
import PasswordForm from "@/components/UserSettings/PasswordForm.vue";
import NotificationsBlock from "@/components/UserSettings/NotificationsBlock.vue";

export default {
  name: "UserSettingsView",

  components: {
    ProfileBlock,
    GeneralInfoBlock,
    PasswordForm,
    NotificationsBlock,
  },
};
</script>

<style scoped lang="scss">
.view {
  height: 100%;
  padding: 24px;

  &__main {
    min-height: 100%;
    padding: 20px 40px;
  }

  &__title {
    margin-bottom: 74px;
    font-size: 28px;
    font-weight: 500;
  }

  &__profile-block {
    display: flex;
    margin-bottom: 56px;
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__column {
    &--1,
    &--2 {
      width: 260px;
      margin-right: 42px;
      margin-bottom: 42px;
    }

    &--3 {
      width: 420px;
      max-width: 90%;
    }
  }

  &__column-title {
    margin-bottom: 20px;
    color: $grey-600;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__column-subtitle {
    margin-bottom: 36px;
    color: $grey-600;
    font-size: 12px;
  }

  @media (max-width: 1264px) {
    &__main {
      padding: 40px;
    }
  }

  @media (max-width: 600px) {
    padding: 0;

    &__title {
      margin-bottom: 40px;
      font-size: 24px;
    }

    &__main {
      padding: 24px;
      box-shadow: none;
    }
  }
}
</style>
