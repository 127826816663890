import { CalendarService } from "../../services";

function resetState() {
  return {
    schedule: {},
    schedule_is_loading: false,

    active_page: {
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
    },

    only_with_notifications: false,

    selected_date: new Date(new Date().getFullYear(), new Date().getMonth()),
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getSchedule({ schedule }) {
      return schedule;
    },

    isSheduleLoading({ schedule_is_loading }) {
      return schedule_is_loading;
    },

    getActivePage({ active_page }) {
      return active_page;
    },

    getSelectedDate({ selected_date }) {
      return selected_date;
    },

    getMonthOfSelectedDate({ selected_date }) {
      const date = new Date(selected_date);

      return `${date.getFullYear()}-` + `${date.getMonth() + 1}`.padStart(2, "0") + `-01`;
    },
  },

  mutations: {
    SET_SCHEDULE(state, schedule) {
      state.schedule = schedule;
    },

    SET_SCHEDULE_IS_LOADING(state, value) {
      state.schedule_is_loading = value;
    },

    SET_ACTIVE_PAGE(state, page) {
      state.active_page = page;
    },

    SET_SELECTED_DATE(state, date) {
      state.selected_date = new Date(date);
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    async loadSchedule({ commit }, orgId) {
      commit("SET_SCHEDULE_IS_LOADING", true);

      try {
        const schedule = await CalendarService.fetchSchedule(orgId);

        commit("SET_SCHEDULE", schedule);
      } finally {
        commit("SET_SCHEDULE_IS_LOADING", false);
      }
    },

    openPage({ commit }, page) {
      commit("SET_ACTIVE_PAGE", page);
    },

    selectDate({ commit }, date) {
      commit("SET_SELECTED_DATE", date);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
