import groupBy from "lodash-es/groupBy";
import pickBy from "lodash-es/pickBy";
import property from "lodash-es/property";
import cloneDeep from "lodash-es/cloneDeep";

import { InfoService } from "../../services";

function resetState() {
  return {
    cities: [],
    timezones: [],
    timezones_info_is_loading: false,
    downloads: [],
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getDeduplicatedCities(state) {
      const duplicatedCityNames = Object.keys(
        pickBy(groupBy(state.cities.map(property("name"))), (key) => key.length > 1)
      );

      return state.cities.map((city) => {
        const _city = cloneDeep(city);

        if (duplicatedCityNames.includes(city.name)) {
          _city.name = `${city.name}, ${city.region}`;
        }

        return _city;
      });
    },

    getCitiesFilteredByName(state, getters) {
      return (name) => {
        return getters["getDeduplicatedCities"].filter(startOfNameMatch);

        function startOfNameMatch(city) {
          return city.name.toLowerCase().startsWith(name.toLowerCase());
        }
      };
    },

    getCityMatchedByName(state, getters) {
      return (name) => {
        return getters["getDeduplicatedCities"].find(fullNameMatch);

        function fullNameMatch(city) {
          return city.name.toLowerCase() == name.toLowerCase();
        }
      };
    },

    getAllTimezones({ timezones }) {
      return timezones;
    },

    getTimezonesFilteredByName({ timezones }) {
      return (name) => {
        return timezones.filter(startOfNameMatch);

        function startOfNameMatch(timezone) {
          return timezone.name.toLowerCase().startsWith(name.toLowerCase());
        }
      };
    },

    getTimezoneMatchedByName({ timezones }) {
      return (name) => {
        return timezones.find(fullNameMatch);

        function fullNameMatch(timezone) {
          return timezone.name.toLowerCase() == name.toLowerCase();
        }
      };
    },

    isTimezonesInfoLoading({ timezones_info_is_loading }) {
      return timezones_info_is_loading;
    },

    getDownloads(state) {
      return state.downloads;
    },
  },

  mutations: {
    SET_CITIES(state, cities) {
      state.cities = cities;
    },

    SET_TIMEZONES(state, timezones) {
      state.timezones = timezones;
    },

    SET_TIMEZONES_INFO_IS_LOADING(state, value) {
      state.timezones_info_is_loading = value;
    },

    SET_DOWNLOADS(state, downloads) {
      state.downloads = downloads;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    async loadCitiesList({ commit }) {
      const cities = await InfoService.fetchCities();

      commit("SET_CITIES", cities);
    },

    async loadTimezonesList({ commit }) {
      commit("SET_TIMEZONES_INFO_IS_LOADING", true);

      try {
        const timezones = await InfoService.fetchTimezones();

        commit("SET_TIMEZONES", timezones);
      } finally {
        commit("SET_TIMEZONES_INFO_IS_LOADING", false);
      }
    },

    async loadDownloadsList({ commit }) {
      const downloads = await InfoService.fetchDownloads();

      commit("SET_DOWNLOADS", downloads);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
