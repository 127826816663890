var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-wrap" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.activeSearch,
          expression: "activeSearch",
        },
      ],
      staticClass: "input-field",
      attrs: { placeholder: "Искать" },
      domProps: { value: _vm.activeSearch },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.activeSearch = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "input-controls" }, [
      _vm.activeSearch.length
        ? _c("img", {
            attrs: {
              src: require("@icons/chat/clear-close-circle.svg"),
              alt: "icon",
            },
            on: {
              click: function ($event) {
                _vm.activeSearch = ""
              },
            },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }