import LoginLayout from "../layouts/Login.vue";

import LoginForm from "../views/Login/LoginForm.vue";
const PasswordRestore = () =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "PasswordRestore" */
    "../views/Login/PasswordRestore.vue"
  );
import NewPassword from "../views/Login/NewPassword.vue";
import ChangePassword from "../views/Login/ChangePassword.vue";
import ServiceConnection from "../views/Login/ServiceConnection.vue";
import SuccessRequest from "../views/Login/SuccessRequest.vue";

export default {
  path: "/login",
  component: LoginLayout,

  beforeEnter(to, from, next) {
    // prevent an authorized user from seeing the Login route
    if (localStorage.getItem("isUserAuthorized")) return next({ name: "App" });

    next();
  },

  children: [
    {
      path: "",
      name: "Login",
      component: LoginForm,
    },

    {
      path: "password-restore",
      name: "PasswordRestore",
      component: PasswordRestore,
    },

    {
      path: "password-restore-success",
      name: "PasswordRestoreSuccess",
      component: SuccessRequest,
      props: {
        image: "password-restore",
        title: "Вам отправлено письмо",
        description: "На вашу почту было отправлено письмо со ссылкой для сброса пароля.",
      },
    },

    // {
    //   path: "service-connection",
    //   name: "ServiceConnection",
    //   component: ServiceConnection,
    // },

    // {
    //   path: "service-connection-success",
    //   name: "ServiceConnectionSuccess",
    //   component: SuccessRequest,
    //   props: {
    //     image: "service-connection",
    //     title: "Ваша заявка отправлена на рассмотрение",
    //     description:
    //       "Наш специалист свяжется с вами по указанному номеру телефона.",
    //   },
    // },

    {
      path: "new-password",
      name: "NewPassword",
      component: NewPassword,

      beforeEnter(to, from, next) {
        console.log("/login/new-password .beforeEnter");

        next();
      },
    },

    {
      path: "change-password",
      name: "ChangePassword",
      component: ChangePassword,
    },

    {
      path: "password-change-success",
      name: "PasswordChangeSuccess",
      component: SuccessRequest,
      props: {
        image: "password-restore",
        title: "Пароль был успешно установлен",
        description: "",
      },
    },
  ],
};
