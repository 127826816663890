<template>
  <div class="root">
    <div class="root__title">Для просмотра раздела Документы установите мобильное приложение 1С:БО</div>

    <a
      v-for="download in mobileAppDownloads"
      :key="download.url_download"
      class="root__link"
      :href="download.url_download"
      target="_blank"
    >
      <img v-if="download.type == 'ios'" src="../../assets/images/appstore.png" alt="appstore.png" />

      <img v-if="download.type == 'android'" src="../../assets/images/googleplay.png" alt="googleplay.png" />
    </a>
  </div>
</template>

<script>
import filter from "lodash-es/filter";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "ForceAppInstallationStub",

  computed: {
    ...mapGetters({
      downloads: "Info/getDownloads",
    }),

    mobileAppDownloads() {
      return filter(this.downloads, ["name", "1С:БО Личный кабинет"]);
    },
  },

  created() {
    if (this.downloads.length == 0) this.loadDownloadsList();
  },

  methods: {
    ...mapActions({
      loadDownloadsList: "Info/loadDownloadsList",
    }),
  },
};
</script>

<style scoped lang="scss">
.root {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: white;
  text-align: center;

  &__title {
    margin-bottom: 16px;
    font-size: 17px;
    font-weight: 500;
  }

  &__link {
    margin-bottom: 6px;
  }
}
</style>
