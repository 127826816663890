var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view" }, [
    _c(
      "div",
      { staticClass: "view__main" },
      [
        _c("div", { staticClass: "view__title" }, [
          _vm._v("Настройки аккаунта"),
        ]),
        _vm._v(" "),
        _c("ProfileBlock", { staticClass: "view__profile-block" }),
        _vm._v(" "),
        _c("div", { staticClass: "view__columns" }, [
          _c(
            "div",
            { staticClass: "view__column view__column--1" },
            [
              _c("div", { staticClass: "view__column-title" }, [
                _vm._v("Общая информация"),
              ]),
              _vm._v(" "),
              _c("GeneralInfoBlock"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "view__column view__column--2" },
            [
              _c("div", { staticClass: "view__column-title" }, [
                _vm._v("Изменить пароль"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "view__column-subtitle" }, [
                _vm._v(
                  "\n          Пароль должен содержать цифры, прописные и строчные буквы и состоять хотя бы из 7 символов\n        "
                ),
              ]),
              _vm._v(" "),
              _c("PasswordForm"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "view__column view__column--3" },
            [
              _c("div", { staticClass: "view__column-title" }, [
                _vm._v("Уведомления"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "view__column-subtitle" }, [
                _vm._v("Разрешите получение уведомлений в браузере"),
              ]),
              _vm._v(" "),
              _c("NotificationsBlock"),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }