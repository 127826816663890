<template>
  <div class="timezone-select">
    <CabTextField
      v-model="input"
      class="timezone-select__input"
      label=""
      name="timezone-select"
      autocomplete="off"
      @blur="onFormInputBlur"
    >
      <template #appendedIcon>
        <img v-if="selectableTimezonesListIsEmpty" src="@icons/arrow-closed.svg" alt="arrow-closed.svg" />
        <img v-else src="@icons/arrow-open.svg" alt="arrow-open.svg" />
      </template>
    </CabTextField>

    <ItemsBox key-string="name" class="timezone-select__timezones-list" :is-empty="selectableTimezonesListIsEmpty">
      <div
        v-for="timezone in selectableTimezones"
        :key="timezone.id"
        tabindex="0"
        class="items-box__item"
        @click="selectTimezone(timezone)"
        @keyup.enter="selectTimezone(timezone)"
        @focus="onTimezonesListFocus"
        @blur="onTimezonesListBlur"
      >
        {{ timezone.name }}
      </div>
    </ItemsBox>
  </div>
</template>

<script>
import matchesProperty from "lodash-es/matchesProperty";

import { mapGetters } from "vuex";

import CabTextField from "../common/CabTextField.vue";
import ItemsBox from "../common/ItemsBox.vue";

export default {
  name: "TimezoneSelect",

  components: {
    CabTextField,
    ItemsBox,
  },

  props: {
    timezoneCityId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      input: "",
      focusIsInTimezonesList: false,
      blankTimezone: {
        name: "",
        id: "",
      },
    };
  },

  computed: {
    ...mapGetters("Info", ["getAllTimezones", "getTimezonesFilteredByName", "getTimezoneMatchedByName"]),

    filteredTimezones() {
      return this.getTimezonesFilteredByName(this.input);
    },

    matchedTimezone() {
      return this.getTimezoneMatchedByName(this.input);
    },

    selectableTimezones() {
      if (this.input == "" || this.matchedTimezone) return [];

      return this.filteredTimezones;
    },

    selectableTimezonesListIsEmpty() {
      return this.selectableTimezones.length === 0;
    },
  },

  watch: {
    matchedTimezone(val) {
      if (val == undefined) {
        this.$emit("select", this.blankTimezone);
      } else {
        this.$emit("select", val);
      }
    },
  },

  created() {
    if (this.timezoneCityId == "") {
      this.input = "";
    } else {
      const selectedTimezone = this.getAllTimezones.find(matchesProperty("id", this.timezoneCityId));

      this.input = selectedTimezone.name;
    }
  },

  methods: {
    onFormInputBlur() {
      setTimeout(() => {
        if (this.focusIsInTimezonesList) return;

        if (!this.matchedTimezone) {
          this.input = "";
        }
      });
    },

    onTimezonesListFocus() {
      this.focusIsInTimezonesList = true;
    },

    onTimezonesListBlur() {
      this.focusIsInTimezonesList = false;
    },

    selectTimezone(timezone) {
      this.input = timezone.name;
      this.$emit("select", timezone);
    },
  },
};
</script>

<style scoped lang="scss">
.timezone-select {
  height: 34px;

  &__timezones-list {
    position: relative;
    z-index: 1;
    top: 14px;
  }
}
</style>
