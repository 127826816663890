<template>
  <div class="cab-text-field">
    <div class="cab-text-field__wrapper" :class="classesForWrapper">
      <label class="cab-text-field__label" :class="classesForLabel" :for="name">
        {{ label }}
      </label>

      <CabInput
        class="cab-text-field__input"
        v-bind="$props"
        :placeholder="$attrs.placeholder"
        :autocomplete="$attrs.autocomplete"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
      />

      <div v-if="$slots.appendedIcon" class="cab-text-field__icon">
        <slot name="appendedIcon"></slot>
      </div>
    </div>

    <ExpandTransition>
      <div v-if="errorMessage" class="cab-text-field__error-message" v-text="errorMessage"></div>
    </ExpandTransition>

    <ExpandTransition>
      <div v-if="hintMessage" class="cab-text-field__hint-message" v-text="hintMessage"></div>
    </ExpandTransition>
  </div>
</template>

<script>
import CabInput from "./CabInput.vue";
import ExpandTransition from "../transitions/Expand.vue";

export default {
  name: "CabTextField",

  components: {
    CabInput,
    ExpandTransition,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: "text",
      validator(value) {
        const types = ["text", "password", "date"];
        return types.includes(value);
      },
    },

    errorMessage: {
      type: String,
      default: "",
    },

    hintMessage: {
      type: String,
      default: "",
    },

    mask: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: false,
    };
  },

  computed: {
    classesForWrapper() {
      return {
        "cab-text-field__wrapper--active": this.active,
        "cab-text-field__wrapper--error": this.errorMessage !== "",
        "cab-text-field__wrapper--disabled": this.disabled,
      };
    },

    classesForLabel() {
      return {
        "cab-text-field__label--active": this.active || this.value.length > 0,
      };
    },
  },

  methods: {
    onFocus() {
      this.active = true;
      this.$emit("focus");
    },

    onBlur() {
      this.active = false;
      this.$emit("blur");
    },

    onInput(event) {
      this.$emit("input", event);
    },
  },
};
</script>

<style scoped lang="scss">
.cab-text-field {
  width: 100%;

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 34px;
    align-items: center;
    border-bottom: 1px solid $grey-300;
    margin-bottom: 6px;
    transition: border-color $short;

    &--active {
      border-bottom: 1px solid $black;
    }

    &--error {
      border-bottom: 1px solid $red-500;
    }
  }

  &__label {
    position: absolute;
    bottom: 0;
    color: $grey-600;
    transform: translate(2px, -10px);
    transform-origin: top left;
    transition: transform $shortest;
    user-select: none;

    &--active {
      transform: translateY(-36px) scale(0.8);
    }
  }

  &__wrapper--disabled {
    border-bottom: 1px solid $grey-300;
    pointer-events: none;
  }

  &__wrapper--disabled &__label {
    color: $grey-400;
  }

  &__wrapper--disabled &__input {
    color: $grey-500;
  }

  &__icon {
    display: flex;
    cursor: pointer;
  }

  &__error-message {
    color: $red-500;
    font-size: 12px;
    white-space: pre-line;
  }

  &__hint-message {
    color: $grey-500;
    font-size: 12px;
    white-space: pre-line;
  }
}
</style>
