import omit from "lodash-es/omit";
import property from "lodash-es/property";

import Vue from "vue";
import Vuex from "vuex";

import User from "./modules/user";
import Organizations from "./modules/organizations";
import Journals from "./modules/journals";
import ActiveJournal from "./modules/active-journal";
import Layout from "./modules/layout";
import Gallery from "./modules/gallery";
import Info from "./modules/info";
import Chat from "./modules/chat";
import Topics from "./modules/topics";
import Domain from "./modules/domain";
import Calendar from "./modules/calendar";
import Documents from "./modules/documents";
import Promo from "./modules/promo";
import TechAudit from "./modules/tech-audit";
import Thread from "./modules/thread";

Vue.use(Vuex);

Vue.config.devtools = true;

function selectOrgId(user, organizations) {
  const orgIds = organizations.map(property("id"));

  const savedOrgIdForUser = localStorage.getItem(`cab-org-id:${user.user_id}`);

  if (orgIds.includes(savedOrgIdForUser)) return savedOrgIdForUser;

  const organization = organizations.find(withConcludingOffer) || organizations[0];

  return organization.id;
}

function withConcludingOffer(organization) {
  const contract = organization.contract;

  return contract.is_offer && contract.status == "concluding";
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",

  actions: {
    async initStore({ dispatch }, { userInfo, topicsList }) {
      const user = omit(userInfo, ["organizations", "notifications"]);
      const { organizations, notifications } = userInfo;

      dispatch("Organizations/init", organizations);
      dispatch("User/init", user);
      dispatch("User/Settings/Notifications/init", notifications);
      dispatch("Topics/setTopicsList", topicsList);

      const orgId = selectOrgId(user, organizations);

      dispatch("Organizations/selectActive", {
        userId: user.user_id,
        orgId,
      });

      // for simplicity reasons this request resides here
      await dispatch("Domain/loadAllUsers");
    },

    resetStore({ dispatch }) {
      dispatch("User/resetState");
      dispatch("Organizations/resetState");
      dispatch("Journals/resetState");
      dispatch("ActiveJournal/resetState");
      dispatch("Layout/resetState");
      dispatch("Gallery/resetState");
      dispatch("Info/resetState");
      dispatch("Chat/resetState");
      dispatch("Topics/resetState");
      dispatch("Domain/resetState");
      dispatch("Calendar/resetState");
      dispatch("Documents/resetState");
      dispatch("Promo/resetState");
      dispatch("TechAudit/resetState");
    },
  },

  modules: {
    User,
    Organizations,
    Journals,
    ActiveJournal,
    Layout,
    Gallery,
    Info,
    Chat,
    Topics,
    Domain,
    Calendar,
    Documents,
    Promo,
    TechAudit,
    Thread,
  },
});
