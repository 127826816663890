<template>
  <Tab :class="classesForTab" :tab="tab" @select="onSelect">
    <div v-if="tab.icon" class="app-tab__icon" v-html="tab.icon"></div>

    <div class="app-tab__text">
      {{ tab.text }}
    </div>

    <div v-if="info" class="app-tab__value">&nbsp;({{ info }})</div>
  </Tab>
</template>

<script>
import intersection from "lodash-es/intersection";

import Tab from "../common/Tab.vue";

export default {
  name: "AppTab",

  components: {
    Tab,
  },

  props: {
    tab: {
      type: Object,
      required: true,
      validator(value) {
        const keys = Object.keys(value);
        const requiredKeys = ["key", "text"];

        if (intersection(keys, requiredKeys).length == 2) return true;
      },
    },

    isActive: {
      type: Boolean,
      required: true,
    },

    isBlocked: {
      type: Boolean,
      default: false,
    },

    info: {
      type: [String, Number],
      default: "",
    },
  },

  computed: {
    classesForTab() {
      return {
        "app-tab": true,
        "app-tab--active": this.isActive,
      };
    },
  },

  methods: {
    onSelect(tab) {
      if (!this.isBlocked) this.$emit("select", tab, this);
    },
  },
};
</script>

// TODO: make scoped
<style lang="scss">
.app-tab {
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 30px;
  box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.05);
  color: $grey-500;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: $short;

  &:first-child {
    border-radius: 10px 0 0;
  }

  &:last-child {
    border-radius: 0 10px 0 0;
  }

  &--active {
    z-index: 1;
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.05), -1px 0 3px 0 rgba(0, 0, 0, 0.05);
    color: $red-400;
  }

  &:hover,
  &--active {
    background: white;
  }

  &__icon {
    display: flex;
    margin-right: 12px;

    svg {
      width: 18px;
    }
  }

  &__text {
    user-select: none;
    white-space: nowrap;
  }
}
</style>
