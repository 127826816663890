import cloneDeep from "lodash-es/cloneDeep";
import property from "lodash-es/property";

import pluralize from "plural-ru";

import formatDate from "../../lib/vue-filters/formatDate";

import { MONTH_NAMES } from "../../utils/constants";

function resetState() {
  return {
    hidden: true,
    message: undefined,
    activation_event: undefined,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    isHidden(state) {
      return state.hidden;
    },

    getMessage(state) {
      return state.message;
    },

    getActivationEvent(state) {
      return state.activation_event;
    },

    commonForQuoteAndClipboardCopy({ message }) {
      let result;

      if (message.attachments) {
        // only 1 attachment
        // only 1 attachment + quote
        // text = fileName
        if (message.attachments.length == 1) {
          result = message.attachments[0].name;
        }
        // several attachments
        // several attachments + quote
        // text = attachments amount
        else {
          result = `${message.attachments.length} ${pluralize(message.attachments.length, "файл", "файла", "файлов")}`;
        }
      }

      // simple message,
      // text + 1 or more attachments
      // text + quote
      // text = text
      if (message.content) result = message.content;

      // protocol

      // journal publication
      if (message.system?.type == "journal") {
        const period = message.system.journal?.period;

        const date = new Date(period);

        const month = MONTH_NAMES[date.getMonth()].toLowerCase();

        const year = date.getFullYear();

        result = `Журнал за ${month} ${year}`;
      }

      // journal comment
      // text = comment
      if (message.system?.type == "journal_comment") {
        result = message.content;
      }

      // statement file
      // text = [name][number]
      if (message.system?.type == "statement_files") {
        const account = message.system.statement_files.account;

        result = `${account.name} ${account.number}`;
      }

      // closing files
      // text = check subtitle getter in ClosingFilesBody component
      if (message.system?.type == "closing_files") {
        const closingFiles = message.system.closing_files;

        let count = closingFiles.count.debits;
        let titles = ["списанию", "списаниям", "списаниям"];

        if (closingFiles.count.clients > 1) {
          count = closingFiles.count.clients;
          titles = ["контрагенту", "контрагентам", "контрагентам"];
        } else if (closingFiles.count.contracts > 1) {
          count = closingFiles.count.contracts;
          titles = ["договору", "договорам", "договорам"];
        }

        result = `По ${count} ${pluralize(count, ...titles)}`;
      }

      return result;
    },

    mismatchBetweenQuoteAndClipboardCopy({ message }) {
      if (message.type == "email") {
        return true;
      } else if (message.document?.type == "onetime") {
        return true;
      } else if (message.document?.type == "invoice") {
        return true;
      } else if (message.document?.type == "contract") {
        return true;
      } else {
        return false;
      }
    },

    onlyForClipboardCopy({ message }) {
      if (message.type == "email") {
        return message.content;
      } else if (message.document?.type == "onetime") {
        const title = "Оказание разовых услуг:";
        const services = `Услуги:\n${message.document.onetime?.services.map(property("name")).join("\n")}`;
        const sum = `Сумма: ${message.document.onetime?.sum} ₽`;

        return `${title}\n${services}\n${sum}`;
      } else if (message.document?.type == "invoice") {
        const title = "Счёт на оплату:";
        const sum = `Сумма: ${message.document.invoice?.sum} ₽`;
        const dueDate = `Оплата до: ${formatDate(message.document.invoice?.due_date, "dd.MM.yyyy")}`;

        return `${title}\n${sum}\n${dueDate}`;
      } else if (message.document?.type == "contract") {
        const title = "Договор на регулярные услуги:";
        const tariff = `Тариф: ${message.document.contract?.tariff?.name}`;
        const marginSum = `ГВ: ${message.document.contract?.margin?.sum} ₽`;
        const marginDate = `ГВ получен: ${formatDate(message.document.contract?.margin?.date, "dd.MM.yyyy")}`;

        return `${title}\n${tariff}\n${marginSum}\n${marginDate}`;
      } else {
        return "";
      }
    },

    onlyForQuote({ message }) {
      if (message.type == "email") {
        return `${message.email?.subject}\n${message.content}`;
      } else if (message.document?.type == "onetime") {
        return "Оказание разовых услуг";
      } else if (message.document?.type == "invoice") {
        return "Счёт на оплату";
      } else if (message.document?.type == "contract") {
        return "Договор на регулярные услуги";
      } else {
        return "";
      }
    },

    textForClipboardCopy(state, getters) {
      if (!getters["mismatchBetweenQuoteAndClipboardCopy"]) {
        return getters["commonForQuoteAndClipboardCopy"];
      } else {
        return getters["onlyForClipboardCopy"];
      }
    },

    textForQuote(state, getters) {
      if (!getters["mismatchBetweenQuoteAndClipboardCopy"]) {
        return getters["commonForQuoteAndClipboardCopy"];
      } else {
        return getters["onlyForQuote"];
      }
    },

    getQuote(state, getters, rootState, rootGetters) {
      const message = cloneDeep(state.message);

      const usersNames = rootGetters["Domain/getUsersNames"];

      message.author.name = usersNames[message.author.id];

      return {
        id: message.id,
        date: message.date,
        author: message.author,
        text: getters["textForQuote"],
        topic: message.topic,
      };
    },
  },

  mutations: {
    SET_MESSAGE(state, message) {
      state.message = message;
    },

    SET_ACTIVATION_EVENT(state, event) {
      state.activation_event = event;
    },

    SET_HIDDEN(state, isHidden) {
      state.hidden = isHidden;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    show({ commit }, { message, event }) {
      commit("SET_MESSAGE", message);
      commit("SET_ACTIVATION_EVENT", event);
      commit("SET_HIDDEN", false);
    },

    hide({ commit }) {
      commit("SET_MESSAGE", undefined);
      commit("SET_ACTIVATION_EVENT", undefined);
      commit("SET_HIDDEN", true);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
