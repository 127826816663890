<template>
  <div class="root">
    <img
      v-if="oldAvatarURL"
      :src="oldAvatarURL"
      class="root__image"
      :class="{
        'root__image--loading': isLoading,
      }"
    />
    <div
      v-else
      class="root__initials"
      :class="{
        'root__initials--loading': isLoading,
      }"
    >
      {{ initials }}
    </div>

    <img v-if="isLoading" src="@icons/bubble-loader.svg" class="root__loader" />

    <label class="root__input" for="avatar-select">
      <img src="@icons/avatar-select.svg" />

      <input id="avatar-select" ref="hidden-input" class="root__hidden-input" type="file" @change="handleInput" />
    </label>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserSettingsAvatarSelect",

  computed: {
    ...mapGetters({
      isLoading: "User/Settings/isAvatarLoading",
      oldAvatarURL: "User/getAvatarURL",
      initials: "User/getInitials",
    }),
  },

  methods: {
    ...mapActions({
      changeAvatar: "User/Settings/changeAvatar",
    }),

    validateAvatar(file) {
      const validTypes = ["image/png", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        this.$notify({
          type: "error",
          title: "Неверный формат изображения",
        });
        return false;
      } else if (file.size > 10 * 1024 * 1024) {
        this.$notify({
          type: "error",
          title: "Изображение слишком большое",
        });
        return false;
      } else {
        return true;
      }
    },

    async handleInput(event) {
      const file = event.target.files[0];

      this.$refs["hidden-input"].value = "";

      const isValid = this.validateAvatar(file);

      if (!isValid) return;

      try {
        await this.changeAvatar(file);

        this.$notify({
          title: "Аватар был успешно изменён",
        });
      } catch {
        this.$notify({
          type: "error",
          title: "Ошибка при смене аватара",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.root {
  position: relative;

  &__image,
  &__initials {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    object-fit: cover;

    &--loading {
      filter: blur(1px);
      opacity: 0.5;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 46px;
    transform: translate(-50%, -50%);
  }

  &__initials {
    background: #fedd46;
    color: $black;
    font-size: 36px;
    font-weight: 500;
  }

  &__input {
    position: absolute;
    right: 3px;
    bottom: 0;
    display: flex;
    width: 27px;
    height: 27px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 50%;
    background: black;
    cursor: pointer;

    img {
      width: 15px;
      opacity: 0.7;
      transition: opacity $shortest;
    }

    &:hover img {
      opacity: 1;
    }
  }

  &__hidden-input {
    display: none;
  }
}
</style>
