import { accountTabs, documentsTabs, tariffTabs, referenceTabs } from "../../utils/layout";

function resetState() {
  return {
    navigation_drawer_opened: false,
    active_account_tab: accountTabs[0],
    active_documents_tab: documentsTabs[0],
    active_tariff_tab: tariffTabs[0],
    active_reference_tab: referenceTabs[0],
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    isNavigationDrawerOpened(state) {
      return state.navigation_drawer_opened;
    },

    getActiveAccountTab(state) {
      return state.active_account_tab;
    },

    getActiveDocumentsTab(state) {
      return state.active_documents_tab;
    },

    getActiveTariffTab(state) {
      return state.active_tariff_tab;
    },

    getActiveReferenceTab(state) {
      return state.active_reference_tab;
    },
  },

  mutations: {
    SET_NAVIGATION_DRAWER_OPENED(state, isOpened) {
      state.navigation_drawer_opened = isOpened;
    },

    SET_ACTIVE_ACCOUNT_TAB(state, tab) {
      state.active_account_tab = tab;
    },

    SET_ACTIVE_DOCUMENTS_TAB(state, tab) {
      state.active_documents_tab = tab;
    },

    SET_ACTIVE_TARIFF_TAB(state, tab) {
      state.active_tariff_tab = tab;
    },

    SET_ACTIVE_REFERENCE_TAB(state, tab) {
      state.active_reference_tab = tab;
    },

    RESET_STATE(state) {
      // we have to save screen size not to lose it after logout
      const code = state.screen_size_code;
      Object.assign(state, resetState());
      state.screen_size_code = code;
    },
  },

  actions: {
    openNavigationDrawer({ commit }) {
      commit("SET_NAVIGATION_DRAWER_OPENED", true);
    },

    closeNavigationDrawer({ commit }) {
      commit("SET_NAVIGATION_DRAWER_OPENED", false);
    },

    setActiveTabForSection({ commit }, { section, tab }) {
      const commitName = `SET_ACTIVE_${section.toUpperCase()}_TAB`;

      commit(commitName, tab);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
