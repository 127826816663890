<template>
  <div class="support-badge">
    <img src="@icons/reference/support-badge.svg" alt="" class="support-badge__icon" />

    <div class="support-badge__wrapper">
      <div class="support-badge__text">Служба поддержки</div>
      <a href="tel:88007700339" class="support-badge__phone">8 800 770-03-39</a>
      <a href="mailto:support@1cbo.ru" class="support-badge__email"> support@1cbo.ru </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReferenceSupportBadge",
};
</script>

<style scoped lang="scss">
.support-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(21, 21, 21, 0.16);

  &__icon {
    width: 90px;
    margin-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    margin-bottom: 16px;
  }

  &__phone {
    margin-bottom: 6px;
    color: $black;
    font-size: 26px;
    font-weight: 700;
    white-space: nowrap;
  }

  &__email {
    color: $blue-500;
    font-size: 16px;
  }

  @media (max-width: 960px) {
    flex-direction: row;

    &__icon {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }

  @media (max-width: 600px) {
    &__icon {
      width: 72px;
    }

    &__phone {
      font-size: 22px;
    }
  }
}
</style>
