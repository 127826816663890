var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reference-instructions" },
    [
      _c(
        "router-link",
        {
          staticClass: "reference-instructions__back",
          attrs: { to: { name: "ReferenceHelp" } },
        },
        [_vm._v(" вернуться назад ")]
      ),
      _vm._v(" "),
      _c("FadeGroupTransition", [
        _vm.$route.params.id == 1
          ? _c("div", { key: "1" }, [
              _c("h1", [_vm._v("Ручная настройка списка информационных баз")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          В список информационных баз ТК могут быть добавлены как приложения сервиса 1С:БухОбслуживание, так и другие\n          информационные базы, доступные пользователю. По вопросам подключения информационных баз, расположенных вне\n          сервиса 1С:БухОбслуживание, следует обращаться к администраторам этих информационных баз.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Для добавления в список информационной базы — приложения сервиса 1С:БухОбслуживание\n          "
                  ),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "Откройте ваше приложение в браузере (так, как вы обычно работаете с ним в сервисе)"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              Скопируйте интернет-адрес приложения в буфер обмена\n              "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/reference/1/pic-1.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В окне запуска 1С:Предприятия нажмите кнопку «Добавить» и выберите «Добавление в\n              список существующей информационной базы». Нажмите «Далее».\n              "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/reference/1/pic-2.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В поле «Наименование информационной базы» укажите понятное Вам название добавляемого\n              приложения.\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              Выберите тип расположения информационной базы «На веб-сервере» и вставьте в поле\n              «Адрес информационной базы» ранее скопированный Вами из браузера адрес приложения. Удалите\n              окончание «/ru_RU/»\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              Нажмите «Далее», «Готово». Информационная база добавлена в список, Вы можете\n              выполнить ее запуск и начать работу\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v("Автоматическая настройка списка информационных баз"),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          Сервис 1С:БухОбслуживание предоставляет механизм, выполняющий автоматическую регистрацию и обновление списка\n          доступных информационных баз на компьютере пользователя.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Порядок подключения к службе списка информационных баз\n          "
                  ),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "В окне списка информационных баз нажмите кнопку «Настройка»."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "В открывшемся окне настроек установите флаг «Отображать в виде дерева»."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В разделе «Адреса интернет-сервисов и списки общих информационных баз» нажмите кнопку\n              «Добавить».\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В поле «Интернет-сервис» введите https://1cbo.1c.ru/a/wcib/hs и нажмите «ОК» в\n              открытых окнах настроек.\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В случае возникновения проблем с вводом логина и пароля попробуйте изменить ссылку на:\n              https://1cbo.1c.ru/a/wcib-noopenid/hs\n              "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/reference/1/pic-4.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              Через некоторое время — обычно две-три секунды — система запросит имя пользователя и пароль\n              для подключения к веб-серверу. Введите те учетные данные, с которыми Вы входите в приложения\n              1С:БухОбслуживание. После успешной авторизации список информационных баз будет содержать доступные Вам\n              информационные базы сервиса 1С:БухОбслуживание\n              "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/reference/1/pic-5.png"),
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm.$route.params.id == 2
          ? _c("div", { key: "2" }, [
              _c("h1", [
                _vm._v(
                  "Инструкция по подключению сервиса 1C-Отчетность в приложении «1С: Предприниматель 2015»"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Подключение к сервису «1С-Отчетность» доступно при наличии оплаченного периода использования\n        программы «1С: Предприниматель 2015» в сервисе 1С: БухОбслуживание на 6 или 12 месяцев.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Перед стартом подключения к сервису 1С-Отчетность зайдите в ваш Личный кабинет на сайте https://1cbo.ru и\n        убедитесь, что приложение 1С: Предприниматель оплачено.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        С использованием сервиса 1С-Отчетность доступна отправка через Интернет отчетов в ФНС, ПФР, ФСС, Росстат,\n        Росалкогольрегулирование.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "Подключение к сервису 1С-Отчетность из приложения 1С: Предприниматель 2015 включает 3 шага"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Шаг 1.")]),
                _vm._v(" Заявление на подключение к сервису «1С-Отчетность»."),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Шаг 2.")]),
                _vm._v(
                  " Запрос на подтверждение заявления о подключении к сервису «1С-Отчетность»,\n        отправка скан-копий документов, подтверждающих полномочия руководителя организации/ИП.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Шаг 3.")]),
                _vm._v(
                  " Одобрение заявления на подключение к «1С-Отчетости» спецоператором связи\n        «Калуга Астрал». Установка сертификата, автоматическая настройка документооборота.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v("\n        Шаг 1 "),
                _c("br"),
                _vm._v(
                  "\n        Подготовить заявление на подключение к сервису 1С-Отчетность\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Шаг 1 включает "),
                _c("strong", [_vm._v("три действия")]),
              ]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _vm._v(
                    "\n          Подготовить в программе 1С: Предприниматель заявление на подключение к сервису электронного документооборота\n          с контролирующими органами — 1С-Отчетность.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("Выбор и установка программы-криптопровайдера;"),
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Настройка электронной подписи (ЭП).")]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Действие 1. Оформление заявления")]),
                _c("br"),
                _vm._v(
                  "\n        В форме элемента справочника «Организации» выберите пункт «Подключение к\n        1С-Отчетности».\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-1.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Открывшийся Помощник поможет заполнить и отправить заявление на подключение. Обратите внимание: оформлять\n        заявление на подключение рекомендуется именно с того компьютера, с которого в будущем планируется осуществлять\n        документооборот.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "В случае работы через браузер потребуется установить внешнюю компоненту для работы с криптографией."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для установки компоненты необходимо в открывшемся окне нажать кнопку Далее.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-2.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Убедитесь в том, что внешняя компонента установлена, о чем будет свидетельствовать соответствующий диалог, в\n        котором нужно нажать кнопку ОК.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-3.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "Действие 2 Выбор и установка программы криптопровайдера"
                  ),
                ]),
                _c("br"),
                _vm._v(
                  "\n        Для работы с сервисом «1С-Отчетность» требуется программа для защиты информации —\n        криптопровайдер.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Помощник проверяет наличие установленных на компьютере программ — криптопровайдеров. Если не установлен\n        ни один криптопровайдер, то Помощник подключения предложит скачать с сайта производителя и установить такую\n        программу. В настоящее время программой поддерживаются криптопровайдеры ViPNet CSP (ОАО\n        «ИнфотеКС») и CryptoPro CSP («КРИПТО-ПРО»).\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        С условиями распространения средств криптографической защиты информации можно ознакомиться на сайтах их\n        разработчиков, ссылки на которые приведены на форме.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-4.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Для завершения установки программы-криптопровайдера компьютер потребуется перезагрузить."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В случае если происходит закрытие Мастера (из-за установки криптопровайдера и перезагрузки компьютера), то при\n        последующем запуске программы помощник откроется на этом же шаге автоматически.\n        "
                ),
                _c("br"),
                _vm._v(
                  "\n        После установки криптопровайдера второй шаг будет выглядеть так:\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-5.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Если установлено два криптопровайдера, то будет выдано предупреждение о необходимости удалить один из них.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "Возврат к Действию 1 «Оформление заявления на подключение к сервису 1С-Отчетность»"
                  ),
                ]),
                _c("br"),
                _vm._v(
                  "\n        На следующем шаге необходимо ознакомиться с текстом соглашения о присоединении к регламенту удостоверяющего\n        центра — Калуги Астрал. Для продолжения нужно подтвердить принятие условий соглашения.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-6.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Регистрационный номер программы «1С: Предприниматель 2015» будет сформирован автоматически."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее необходимо выбрать организацию, которую планируется подключить, сотрудника — владельца сертификата\n        ключа и перечислить контролирующие органы в которые планируется сдавать отчетность. При установке флажка\n        «Росстат» автоматически установится флажок «ФНС» (подключение к Росстату невозможно\n        без подключения к ФНС).\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-7.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее требуется убедиться в корректности заполнения сведений об организации. Основные реквизиты организации\n        можно отредактировать в справочнике, открыв его по ссылке с названием организации.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Контактную информацию можно править непосредственно в форме мастера. Здесь указываются и контактные телефоны,\n        по которым представитель специализированного оператора связи свяжется с пользователем для встречи и оформления\n        документов по подключению.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-8.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        На следующем шаге необходимо проверить сведения о лице, на которое будет издан сертификат квалифицированной\n        цифровой подписи, необходимый для осуществления документооборота. Обычно в качестве такого сотрудника\n        выступает руководитель организации. При выборе сотрудника программа заполнит поля со сведениями о нем. Эти\n        сведения необходимо тщательно проверить и при необходимости заполнить (отредактировать по ссылке) недостающие\n        обязательные поля.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-9.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее требуется проверить и при необходимости отредактировать коды инспекций ФНС, отделения ПФР,\n        территориального органа Росстата. В случае, если организация представляет отчетность не в один налоговый\n        орган, указывается несколько кодов ИФНС.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-10.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее будет предложено еще раз проверить правильность всех сведений, указанных в заявлении.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-11.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v("Действие 3. Генерация ключа электронной подписи"),
                ]),
                _c("br"),
                _vm._v(
                  "\n        Ключ электронной подписи будет сгенерирован с помощью программы — криптопровайдера.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-12.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Ключ, в зависимости от криптопровайдера, может быть размещен на внешнем устройстве или на компьютере\n        пользователя.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-13.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        После выбора размещения электронного ключа будет предложено задать пароль.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-14.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        При создании ключа для работы генератора случайных чисел потребуется в произвольном порядке нажимать клавиши\n        на клавиатуре или двигать мышью.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-15.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        По завершении создания ключа потребуется еще раз ввести пароль, и заявление будет передано через Интернет\n        спецоператору связи.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-16.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-17.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v("\n        Шаг 2"),
                _c("br"),
                _vm._v(
                  "\n        Направить запрос на подтверждение заявления о подключении к сервису 1С-Отчетность.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Запрос на подтверждение заявления о подключении к сервису 1С-Отчетность направляется в фирму «1С»,\n        как представителя спецоператора связи, на адрес bo@1c. ru и включает: "
                ),
                _c("br"),
                _vm._v(
                  "\n        1. Письмо с темой «1С-Отчетность для 1С: Предприниматля 2015» и текстом по шаблону:"
                ),
                _c("br"),
                _vm._v(
                  "\n        «Прошу подтвердить заявление о подключении ¬¬___________________(ИНН/КПП)\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        К защищенному электронному документообороту. Скан-копии документов, подтверждающих полномочия руководителя\n        ______________________________ в приложении».\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        2. Скан-копии документов, подтверждающих полномочия руководителя организации/ИП во вложении к письму."
                ),
                _c("br"),
                _vm._v("\n        a) для юридического лица:\n      "),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "копия свидетельства о постановке на учет организации-клиента, с указанием ИНН/КПП;"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "копия общегражданского паспорта руководителя (достаточно разворота с ФИО);"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          заверенная копия приказа о назначении руководителя на должность (или другой документ, подтверждающий, что\n          этот человек — руководитель)\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "копия Соглашения об ЭДО с ОПФР (в случае, если в заявке указано такое направление сдачи отчетности)."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("b) для индивидуального предпринимателя:")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "копия свидетельства о постановке на учет с указанием ИНН;"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "копия общегражданского паспорта ИП (достаточно разворота с ФИО);"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "копия Соглашения об ЭДО с ОПФР (в случае, если в заявке указано такое направление сдачи отчетности)."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        После получения запроса сотрудник фирмы «1С» свяжется с пользователем, используя данные, указанные\n        в секции «Контактная информация» заявления, для решения организационных вопросов по подключению.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v("\n        Шаг 3. "),
                _c("br"),
                _vm._v(
                  "\n        Одобрение заявления на подключение к «1С-Отчетости» спецоператором связи «Калуга\n        Астрал». "
                ),
                _c("br"),
                _vm._v(
                  "\n        Установка сертификата, автоматическая настройка документооборота.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для продолжения работы требуется дождаться одобрения заявления на подключение к сервису 1С-Отчетность от\n        спецоператора связи Калуга-Астрал.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Увидеть статус заявления можно так: подсистема Отчеты/Регламентированные отчеты/Настройки, далее «Список\n        заявлений».\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Из Списка заявлений можно перейти к конкретному заявлению."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для проверки статуса заявления можно воспользоваться кнопкой «Обновить статусы заявлений».\n        Программа автоматически предложит обновить статус заявления с сервера спецоператора, если с момента отправки\n        прошло больше суток.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-18.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В процессе подключения удостоверяющим центром будет издан сертификат ключа электронной подписи на имя\n        сотрудника, указанного при оформлении заявки, и на сервере спецоператора для нового абонента будет создана\n        учетная запись, которая необходима для осуществления документооборота.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В случае если при подключении к сервису «1С-Отчетность» возникла ошибка, то причину можно\n        посмотреть в Помощнике.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-19.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Если заявление одобрено, можно приступать к завершению настройки электронного документооборота с\n        контролирующими органами, при этом на экране будет показана следующая форма.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-20.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Потребуется еще раз ввести пароль.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-21.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее будет предложено выбрать пользователей, которые смогут использовать 1С-Отчетность по организации. Если в\n        системе только один пользователь этот шаг будет пропущен.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-22.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        И на заключительном шаге будет показан следующий диалог.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-23.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        После того как все необходимые настройки произведены, пользователь может незамедлительно приступить к\n        документообороту (отправке отчетности и обмену прочими документами).\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В будущем, при изменении настроек документооборота (например, смене сертификатов в контролирующем органе), эти\n        изменения будут автоматически отражаться в информационной базе — пользователю не придется отслеживать и\n        отражать изменения в настройках программы самостоятельно.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-24.png"),
                    alt: "",
                  },
                }),
              ]),
            ])
          : _vm.$route.params.id == 3
          ? _c("div", { key: "3" }, [
              _c("h1", [_vm._v("Работа через тонкий клиент 1С")]),
              _vm._v(" "),
              _c("h3", [_vm._v("Назначение Тонкого клиента 1С:Предприятие")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Тонкий клиент 1С:Предприятие (ТК) — это специальная версия программ 1С:Предприятие, позволяющая работать\n        с информационными базами, размещенными в облачных сервисах или на сервере 1С:Предприятие, в частности —\n        с информационными базами в сервисе 1С:БухОбслуживание.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для работы в приложениях сервиса 1С:БухОбслуживание рекомендуется использовать именно ТК в связи с его большей\n        устойчивостью, скоростью работы и диагностируемостью по сравнению с браузерами.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Системные требования")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Минимальные системные требования ТК соответствуют таковым для платформы 1С:Предприятие 8.3 и опубликованы в\n        составе документации по платформе 1С:Предприятие:\n      "
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          Операционная система Windows XP Service Pack 2, Windows Server 2003 и выше, Fedora 17, Mint 12, Ubuntu 12.04\n          LTS, Alt Linux СПТ 6.0\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("Процессор Intel Pentium/Celeron 1800 МГц и выше"),
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Оперативная память 1 Гбайт и выше")]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Жесткий диск (при установке используется около 70 Мбайт)"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("SVGA-дисплей")]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Рекомендуемые параметры оборудования, обеспечивающие комфортную работу в Тонком клиенте 1С:Предприятие,\n        следующие:\n      "
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Операционная система Windows 7 и выше, Windows Server 2008 и выше"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("Двухъядерный процессор Intel Core2 Duo и выше"),
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Оперативная память 2 Гбайт и выше")]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "При несоблюдении рекомендуемых параметров возможно снижение быстродействия и стабильности работы ТК."
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Вопросы лицензирования")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Клиентам сервиса 1С:БухОбслуживание предоставляется право использования программного обеспечения «Тонкий\n        клиент 1С:Предприятие» в соответствии с лицензионной политикой 1С.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Сервис 1С:БухОбслуживание предоставляет пользователю необходимые лицензии 1С:Предприятие при отсутствии\n        таковых у пользователя. Лицензии предоставляются только для работы в приложениях сервиса 1С:БухОбслуживание,\n        на любом компьютере, путем выделения запущенному приложению лицензии из пула лицензий сервиса на период сеанса\n        работы.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Установка ТК")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Установочный пакет Тонкого клиента 1С:Предприятие для Windows Вы можете скачать по следующей ссылке:\n        https://1cbo.1c. ru/download/setuptc. zip. Версия ТК, расположенная по приведенной ссылке, всегда совпадает с\n        версией платформы 1С:Предприятие, используемой в сервисе 1С:БухОбслуживание.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Для установки ТК требуются права администратора операционной системы."
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Порядок установки ТК:")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          Скачайте установочный пакет Тонкого клиента по ссылке, приведенной выше. Сохраните архивный файл\n          установочного пакета на Ваш компьютер.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Распакуйте сохраненный архив и двойным кликом запустите файл setup. exe. Расширение «.exe» может\n          не отображаться в зависимости от настроек Вашего компьютера, в этом случае Вы можете опознать требуемый файл\n          по виду иконки\n          "
                  ),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/reference/3/pic-1.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/reference/3/pic-2.png"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Следуйте рекомендациям программы установки, нажимая на кнопку «Далее». Для завершения установки\n          нажмите кнопку «Готово». Какие-либо изменения параметров установки, как правило, не требуются.\n          "
                  ),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/reference/3/pic-3.png"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          После завершения установки требуется настроить список информационных баз вручную либо при помощи службы\n          списка информационных баз 1С:БухОбслуживание.\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Обновление ТК")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Процесс обновления тонкого клиента 1С:Предприятие представляет собой установку новой версии программы. При\n        этом удаление старых версий не обязательно, старые версии сохраняют работоспособность. В случае отсутствия\n        нужды в старых версиях они могут быть удалены обычными методами без нарушения функциональности актуальных\n        версий.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Для установки новых версий тонкого клиента 1С:Предприятие могут применяться следующие сценарии:"
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [_vm._v("Обновление вручную")]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Автоматическое обновление средствами платформы 1С:Предприятие"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Автоматическое обновление средствами администрирования ОС"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Выбор конкретного сценария определяется прежде всего требованиями к безопасности локальной информационной\n        системы. Ключевым критерием безопасности является уровень прав пользователя, в общем случае представляемый как\n        ограниченный либо административный.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В случае ограниченного набора прав пользователь, как правило, не имеет права на установку программного\n        обеспечения. В этом случае установка программного обеспечения может проводиться пользователем с временным\n        повышением уровня прав либо операционной системой. Сценарий установки должен предусматривать такую\n        потребность."
                ),
                _c("br"),
                _vm._v(
                  "\n        Если же пользователь обладает административными привилегиями, то вопрос достаточности прав для установки\n        программного обеспечения не стоит и допустимо использовать любой сценарий.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Обновление вручную применяется, как правило, на незначительном количестве рабочих мест. При этом процесс\n        установки программы должен быть запущен с повышенными привилегиями.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Обновление вручную выполняется идентично установке ТК"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Автоматическое обновление средствами платформы 1С:Предприятие"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        При запуске информационной базы, подключенной в режиме «На веб-сервере» тонкий клиент\n        1С:Предприятие получает информацию о требуемой версии платформы и о расположении в интернете файла\n        дистрибутива платформы, если на веб-сервере опубликован такой дистрибутив. Сервис 1С:БухОбслуживание всегда\n        публикует дистрибутив актуальной версии тонкого клиента.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        При наличии информации о расположении дистрибутива тонкий клиент автоматически скачивает файл и запускает\n        процедуру быстрой установки (т. е. установка производится без запроса каких-либо параметров у пользователя\n        — используются параметры предыдущей установки), после чего перезапускается уже в нужной версии.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Обновление происходит полностью автоматически и требует наличия у пользователя права на установку программного\n        обеспечения.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Варианты реализации:")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Предоставление пользователю административных привилегий"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Включение элемента групповых политик «Всегда производить установку с повышенными привилегиями»\n          (англ. Always install elevated)\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Автоматическое обновление средствами администрирования ОС"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Развертывание приложения осуществляется в среде Active Directory из дистрибутива, опубликованного\n          администратором. Процедура развертывания приложения средствами групповых политик Active Directory описана в\n          документации Microsoft: https://support.microsoft.com/en-us/kb/816102\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Развертывание приложения рекомендуется выполнять в режиме «Назначенный» для компьютеров, а не\n          пользователей домена\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "В развертываемом пакете рекомендуется добавить следующие трансформы"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "1049.mst — обеспечивает русский язык установленного приложения"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Adminstallrestart. mst — реализует предложение перезагрузить компьютер для выполнения автоматической\n          установки нужной версии\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Указывать в развертываемом пакете обновляемые пакеты не требуется"
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }