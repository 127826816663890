<template>
  <div class="input-wrap">
    <input v-model="activeSearch" class="input-field" placeholder="Искать" />
    <div class="input-controls">
      <img v-if="activeSearch.length" src="@icons/chat/clear-close-circle.svg" alt="icon" @click="activeSearch = ''" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    activeSearch: "",
    timerSearch: null,
  }),
  watch: {
    activeSearch() {
      if (this.timerSearch) {
        clearTimeout(this.timerSearch);
      }
      this.timerSearch = setTimeout(() => {
        this.$emit("value", this.activeSearch);
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.input {
  &-wrap {
    position: relative;
    display: flex;
    width: 190px;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 24px;
    margin-left: 28px;
  }

  &-field {
    width: 100%;
    box-sizing: border-box;
    padding: 7px 60px 7px 12px;
    border-radius: 5px;
    border-bottom: 1px solid #e9e9e9;
    color: #333;
    font-size: 13px;
    outline: none;
    transition: 0.2s;

    &::placeholder {
      color: #adadad;
    }

    &::-ms-clear {
      display: none;
    }

    &:focus {
      border-color: #ffe676;
      box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(254, 221, 70, 0.5);
    }
  }

  &-controls {
    position: absolute;
    right: 12px;
    display: flex;
    cursor: pointer;
  }
}
</style>
