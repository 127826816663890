<template>
  <div class="slider">
    <div class="slider__button slider__prev" :class="{ blocked: isAtStart }" @click="$emit('slide-prev')">
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.36 4.32c.7.53.85 1.53.32 2.24L11.6 12l4.08 5.44a1.6 1.6 0 1 1-2.56 1.92l-4.8-6.4a1.6 1.6 0 0 1 0-1.92l4.8-6.4a1.6 1.6 0 0 1 2.24-.32Z"
          fill="currentColor"
        />
      </svg>
    </div>

    <div v-for="(agent, i) in agents" :key="agent.id" class="slider__agent-card agent-card">
      <div class="agent-card__avatar avatar">
        <img v-if="agent.avatar && agent.avatar.thumb" :src="agent.avatar.thumb.url" class="avatar__image" />

        <div v-else class="avatar__image">
          {{ agent.name | nameInitials }}
        </div>
      </div>

      <div class="agent-card__name">
        {{ agent.name }}
      </div>

      <div class="agent-card__position">
        {{ agent.position }}
      </div>

      <a v-if="agent.email" :href="`mailto:${agent.email}`" class="agent-card__email">
        {{ agent.email }}
      </a>

      <a v-if="agent.phone" :href="`tel:${agent.phone}`" class="agent-card__phone">
        {{ agent.phone }}
      </a>
    </div>

    <div class="slider__button slider__next" :class="{ blocked: isAtEnd }" @click="$emit('slide-next')">
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.64 19.68a1.6 1.6 0 0 1-.32-2.24L12.4 12 8.32 6.56a1.6 1.6 0 1 1 2.56-1.92l4.8 6.4a1.6 1.6 0 0 1 0 1.92l-4.8 6.4a1.6 1.6 0 0 1-2.24.32Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentsSlider",

  props: {
    agents: {
      type: Array,
      required: true,
    },

    isAtStart: {
      type: Boolean,
      required: true,
    },

    isAtEnd: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.agent-card {
  overflow: hidden;
  min-height: 142px;
  flex: 1;
  padding: 0 10px;

  &__avatar {
    margin: 0 auto 6px;
  }

  &__name {
    min-height: 15px;
    font-size: 13px;
    text-align: center;
  }

  &__position {
    min-height: 14px;
    margin-bottom: 6px;
    color: $grey-400;
    font-size: 12px;
    text-align: center;
  }

  &__email {
    @include ellipsis;

    display: block;
    margin-bottom: 4px;
    color: $blue-400;
    font-size: 12px;
    text-align: center;
  }

  &__phone {
    display: block;
    color: $black;
    font-size: 12px;
    text-align: center;
  }
}

.avatar {
  display: flex;
  width: 34px;
  height: 34px;
  min-height: 34px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;

  &__image {
    display: flex;
    width: 34px;
    min-width: 34px;
    height: 34px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fedd46;
    color: $black;
    font-size: 14px;
    font-weight: 700;
    object-fit: cover;
  }
}

.slider {
  display: flex;
  align-items: flex-start;

  &__button {
    width: 24px;
    align-self: center;
    color: $grey-600;
    cursor: pointer;
    transition: color $shortest;
    user-select: none;

    &:hover {
      color: $grey-700;
    }

    &.blocked {
      opacity: 0.1;
      pointer-events: none;
      user-select: none;
    }
  }
}
</style>
