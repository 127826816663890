import { union } from "lodash-es";
import { allTopicsFilters } from "../../utils/constants";
import intersection from "lodash-es/intersection";
import xor from "lodash-es/xor";

function resetState() {
  return {
    allMainSelectedFilters: [],
    allExtraSelectedFilters: [],
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    mainSelectedFilters({ allMainSelectedFilters }) {
      return allMainSelectedFilters;
    },

    extraSelectedFilters({ allExtraSelectedFilters }) {
      return allExtraSelectedFilters;
    },

    mainAppliedFilters: (state, getters) => (topicId) => {
      return intersection(getters.topicsFilters(topicId).messages_types, state.allMainSelectedFilters);
    },

    extraAppliedFilters(state) {
      return state.allExtraSelectedFilters;
    },

    topicsFilters: (state, getters, rootState, rootGetters) => (topicId) => {
      const topicName = rootGetters["Topics/idsToNamesMap"][topicId];
      const isFilterSetDefined = Object.keys(allTopicsFilters).includes(topicName);

      return allTopicsFilters[isFilterSetDefined ? topicName : undefined];
    },
  },

  mutations: {
    SET_MAIN_SELECTED_FILTERS(state, filters) {
      state.allMainSelectedFilters = filters;
    },

    SET_EXTRA_SELECTED_FILTERS(state, filters) {
      state.allExtraSelectedFilters = filters;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    applySelectedFilters({ state, commit }) {
      commit("SET_MAIN_SELECTED_FILTERS", state.allMainSelectedFilters);
      commit("SET_EXTRA_SELECTED_FILTERS", state.allExtraSelectedFilters);
    },

    resetSelectedFilters({ commit }) {
      commit("SET_MAIN_SELECTED_FILTERS", []);
      commit("SET_EXTRA_SELECTED_FILTERS", []);
    },

    changeMainAppliedFilter({ commit }, filters) {
      commit("SET_MAIN_SELECTED_FILTERS", filters);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
