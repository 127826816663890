import { createPopper } from "@popperjs/core";

export default {
  inserted(el, { value, modifiers }) {
    const strategy = value.strategy || "absolute";
    const placement = value.placement || "top";
    let isClickable = modifiers.clickable;
    let isClicked = false;

    const hintBody = document.createElement("div");
    hintBody.className = "app-hint__body";

    if (value.html) {
      hintBody.innerHTML = value.html;
    } else {
      hintBody.innerText = value.text;
    }

    const offsetY = value.offsetY || 0;

    const arrow = document.createElement("div");
    arrow.className = "app-hint__arrow";
    arrow.setAttribute("data-popper-arrow", "");
    hintBody.appendChild(arrow);

    el.insertAdjacentElement("afterend", hintBody);

    const popperInstance = createPopper(el, hintBody, {
      placement: placement,

      strategy: strategy,

      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8 + offsetY],
          },
        },
        {
          name: "preventOverflow",
          options: {
            padding: 16,
            boundary: value.boundarySelector ? document.querySelector(value.boundarySelector) : "clippingParents",
          },
        },
        {
          name: "eventListeners",
          enabled: false,
        },
      ],
    });

    el.show = function () {
      const disabled = el.getAttribute("hint-disabled") === "true"; // атрибут hint-disabled задавать строкой

      if (!disabled) {
        hintBody.setAttribute("data-show", "");
        popperInstance.update();
      }
    };

    el.hide = function () {
      hintBody.removeAttribute("data-show");
    };

    el.onClickOutside = function (event) {
      const isClickOutside = !el.contains(event.target);

      if (!isClickOutside) {
        return;
      }

      isClicked = false;
      el.hide();
    };

    el.onMouseLeave = function () {
      if (isClicked) return;
      el.hide();
    };

    el.addEventListener("mouseenter", el.show);

    el.addEventListener("mouseleave", el.onMouseLeave);

    if (strategy === "fixed") {
      window.addEventListener("scroll", el.hide, true);
    }

    if (isClickable) {
      el.addEventListener("click", function onClick(event) {
        event.stopPropagation();

        if (isClicked) {
          isClicked = false;
          el.hide();
        } else {
          isClicked = true;
          el.show();
        }

        document.documentElement.addEventListener("click", el.onClickOutside, {
          once: true,
        });
      });
    }
  },

  unbind(el) {
    el.removeEventListener("mouseenter", el.show);
    el.removeEventListener("mouseleave", el.show);
    el.removeEventListener("click", el.show);
    document.documentElement.removeEventListener("click", el.onClickOutside);
    window.removeEventListener("scroll", el.hide, true);
  },
};
