<template>
  <div class="cab-textarea">
    <div class="cab-textarea__wrapper" :class="classesForWrapper">
      <div class="cab-textarea__textarea-wrapper">
        <textarea
          :id="name"
          ref="textarea"
          class="cab-textarea__textarea"
          :class="classesForTextarea"
          :style="{ maxHeight }"
          :value="value"
          :name="name"
          :rows="rows"
          :maxlength="maxlength"
          :placeholder="$attrs.placeholder"
          @input="onInput($event.target.value)"
          @focus="onFocus"
          @blur="onBlur"
        ></textarea>

        <div v-if="highlightText" ref="highlight" class="cab-textarea__highlight">
          <slot name="highlight">
            <div class="cab-textarea__highlight-text">
              <span>{{ value }}</span>
            </div>
          </slot>
        </div>
      </div>
      <label :for="name" :class="{ 'cab-textarea__label--active': active || value }" class="cab-textarea__label">
        {{ label }}
      </label>
    </div>

    <ExpandTransition>
      <div v-if="errorMessage" class="cab-textarea__error-message" v-text="errorMessage"></div>
    </ExpandTransition>
  </div>
</template>

<script>
import ExpandTransition from "@/components/transitions/Expand.vue";

export default {
  name: "HighlightTextarea",

  components: {
    ExpandTransition,
  },

  props: {
    value: {
      type: String,
      default: "", // не делать required: true - чтобы если в модели придет null, то преобразовать его в ""
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    maxHeight: {
      type: String,
      default: "76px",
    },

    errorMessage: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    rows: {
      type: [String, Number],
      default: "",
    },

    maxlength: {
      type: [String, Number],
      default: "",
    },

    highlightText: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: false,
      placeholderIsVisible: false,
    };
  },

  computed: {
    classesForWrapper() {
      return {
        "cab-textarea__wrapper--active": this.active,
        "cab-textarea__wrapper--error": this.errorMessage !== "",
        "cab-textarea__wrapper--disabled": this.disabled,
      };
    },

    classesForTextarea() {
      return {
        "cab-textarea__textarea--not--empty": this.value,
        "cab-textarea__textarea--visible-placeholder": this.placeholderIsVisible,
      };
    },
  },

  updated() {
    this.resizeTextarea();
    this.resizeHighlight();
  },

  mounted() {
    this.resizeTextarea();
    this.resizeHighlight();
  },

  methods: {
    resizeTextarea() {
      const oldHeight = this.$refs["textarea"].style.height;
      this.$refs["textarea"].style.height = "auto";
      const newHeight = this.$refs["textarea"].scrollHeight;
      this.$refs["textarea"].style.height = oldHeight;

      requestAnimationFrame(() => {
        this.$refs["textarea"].style.height = newHeight + "px";
      });
    },

    resizeHighlight() {
      if (this.$refs.highlight) {
        const textareaStyles = window.getComputedStyle(this.$refs.textarea);
        this.$refs.highlight.style.padding = textareaStyles.padding;
        this.$refs.highlight.style.margin = textareaStyles.margin;
      }
    },

    onInput(value) {
      this.$emit("input", value);

      this.resizeTextarea();
    },

    onFocus() {
      this.active = true;
      this.placeholderIsVisible = true;

      this.$emit("focus");
    },

    onBlur() {
      this.active = false;
      this.placeholderIsVisible = false;

      this.$emit("blur");
    },
  },
};
</script>

<style scoped lang="scss">
.cab-textarea {
  &__wrapper {
    position: relative;
    overflow: auto;
    padding-top: 14px;
    border-bottom: 1px solid $grey-300;

    &--active {
      border-bottom: 1px solid $black;
    }

    &--error {
      border-bottom: 1px solid $red-500;
    }
  }

  &__textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    scrollbar-color: $grey-300 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $grey-300;
    }

    &::placeholder {
      opacity: 0;
      transition: opacity $short;
    }

    &--visible-placeholder {
      &::placeholder {
        opacity: 1;
      }
    }
  }

  &__label {
    position: absolute;
    top: 10px;
    left: 0;
    color: $grey-600;
    cursor: pointer;
    transition: $shortest;

    &--active {
      top: -2px;
      font-size: 12px;
    }
  }

  &__wrapper--disabled {
    border-bottom: 1px solid $grey-300;
    pointer-events: none;
  }

  &__wrapper--disabled &__label {
    color: $grey-400;
  }

  &__wrapper--disabled &__textarea {
    color: $grey-500;
  }

  &__error-message {
    margin-top: 6px;
    color: $red-500;
    font-size: 12px;
    white-space: pre-line;
  }

  &__textarea-wrapper {
    position: relative;
  }

  &__highlight {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__highlight-text {
    position: relative;
    left: 4px;

    * {
      position: relative;
    }

    &::before {
      position: absolute;
      top: -2px;
      left: -6px;
      width: calc(100% + 12px);
      height: calc(100% + 4px);
      border-radius: 10px;
      background-color: #ffe676;
      content: "";
    }
  }
}
</style>
