<template>
  <div class="app-modal" tabindex="0" @keyup.esc="$emit('close')">
    <div class="app-modal__overlay" @click="$emit('close')"></div>

    <div
      ref="container"
      class="app-modal__content app-modal-content"
      :class="{ 'app-modal-content--clean': $slots.clean }"
      :style="{ maxWidth: maxWidth + 'px' }"
    >
      <slot v-if="$slots.clean" name="clean"></slot>

      <template v-else>
        <div name="body" class="app-modal-content__close" @click="$emit('close')" v-html="crossIcon"></div>

        <div class="app-modal-content__header" :class="classes.header">
          <slot name="header"></slot>
        </div>

        <div class="app-modal-content__subheader" :class="classes.subheader">
          <slot name="subheader"></slot>
        </div>

        <div class="app-modal-content__body" :class="classes.body">
          <slot name="body"></slot>
        </div>

        <div class="app-modal-content__footer" :class="classes.footer">
          <slot name="footer"></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import crossIcon from "../../assets/icons/raw-svg/cross.svg";

export default {
  name: "AppModal",

  props: {
    maxWidth: {
      type: Number,
      default: 1100,
    },

    classes: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      crossIcon,
    };
  },

  mounted() {
    document.body.appendChild(this.$el);
    this.$el.focus(); // to make possible close on "esc"
    this.$emit("open", this.$refs["container"]);
  },

  destroyed() {
    this.$el.remove();
  },
};
</script>

<style scoped lang="scss">
.app-modal {
  position: absolute;
  z-index: 3;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.3);
  }

  &__content {
    position: absolute;
    display: flex;
    border-radius: 6px;
    margin: 12px;
    animation-duration: $short;
    animation-name: zoom-in;
    background-color: white;
  }
}

.app-modal-content {
  position: relative;
  display: flex;
  width: calc(100% - 2 * 12px);
  max-height: calc(100% - 2 * 12px);
  flex-direction: column;
  padding: 20px 4px 20px 20px;

  &--clean {
    padding: 0;

    & > * {
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  &__header {
    padding-right: 36px;
    padding-bottom: 24px;
    font-size: 20px;
    font-weight: 500;
  }

  &__subheader {
    padding-right: 16px;
  }

  &__body {
    padding-right: 16px;
    overflow-y: auto;
    scrollbar-color: $grey-300 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $grey-300;
    }
  }

  @media (max-width: 600px) {
    &__header {
      padding-bottom: 10px;
    }
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}
</style>
