<template>
  <form class="login-form">
    <img class="login-form__logo" src="@icons/logo.svg" alt="logo.svg" />

    <CabTextField
      v-model="$v.username.$model"
      class="login-form__username-input"
      label="Логин"
      name="login-form-username"
      placeholder=" Введите логин"
      :error-message="errorMessage"
      @input="errorMessage = ''"
    />

    <CabTextField
      v-model="password"
      class="login-form__password-input"
      label="Пароль"
      name="login-form-password"
      placeholder=" Введите пароль"
      :type="passwordType"
      :error-message="errorMessage"
      @input="errorMessage = ''"
    >
      <template #appendedIcon>
        <EyeIcon class="login-form__password-icon" :is-striked="isPasswordVisible" @click.native="onIconClick" />
      </template>
    </CabTextField>

    <RouterLink class="login-form__password-restore" :to="{ name: 'PasswordRestore' }"> Забыли пароль? </RouterLink>

    <div tabindex="0" :class="classesForLoginButton" @click="loginUser({ username, password })">
      Войти
      <FadeTransition>
        <div v-if="isInProcessOfLogin" class="login-form__loader">
          <img src="@icons/bubble-loader.svg" width="24" />
        </div>
      </FadeTransition>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/dist/validators.min";

import EyeIcon from "../../components/common/EyeIcon.vue";
import CabTextField from "../../components/common/CabTextField.vue";
import FadeTransition from "../../components/transitions/Fade.vue";

import loginMixin from "./loginMixin";

export default {
  name: "LoginForm",

  components: {
    CabTextField,
    EyeIcon,
    FadeTransition,
  },

  mixins: [loginMixin],

  data() {
    return {
      username: "",
      password: "",
      isPasswordVisible: false,
      errorMessage: "",
    };
  },

  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },

  computed: {
    passwordType() {
      return this.isPasswordVisible ? "text" : "password";
    },

    classesForLoginButton() {
      return {
        "login-form__login-button": true,
        "login-form__login-button--blocked": this.$v.$invalid || this.isInProcessOfLogin,
      };
    },
  },

  activated() {
    if (this.$route.params.login) {
      this.username = this.$route.params.login;
    }
  },

  mounted() {
    this.$el.addEventListener(
      "keyup",
      function pressEnter(event) {
        if (event.keyCode === 13 && !this.isInProcessOfLogin) {
          if (!this.$v.$invalid) {
            // hide screen keyboard
            document.body.querySelectorAll("input").forEach((input) => input.blur());

            this.loginUser({
              username: this.username,
              password: this.password,
            });
          }
        }
      }.bind(this)
    );
  },

  methods: {
    onIconClick() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
};
</script>

<style scoped lang="scss">
.login-form {
  display: flex;
  width: 332px;
  max-width: 96%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__logo {
    margin-bottom: 60px;
  }

  &__username-input {
    margin-bottom: 40px;
  }

  &__password-input {
    margin-bottom: 6px;
  }

  &__password-icon {
    width: 20px;
    fill: $grey-400;
  }

  &__password-restore {
    align-self: flex-end;
    margin-bottom: 36px;
    font-size: 12px;
  }

  &__login-button {
    @include gradient-button;

    position: relative;
    margin-bottom: 12px;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $yellow-400;
    border-radius: 6px;
    background: white;
  }

  &__call-to-action {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__suggestion {
    color: $grey-600;
    font-size: 12px;
  }

  &__link {
    color: #5383ff;
    font-size: 12px;
  }
}
</style>
