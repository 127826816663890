var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CabTooltip",
    {
      attrs: { placement: "bottom-end", interactive: true, timeout: 0 },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "history-action" },
                [
                  _c("AppIcon", {
                    staticClass: "history-icon",
                    attrs: { name: "clock-back" },
                  }),
                  _vm._v(" История действий"),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "root__tooltip history-tooltip" }, [
        _c(
          "div",
          { staticClass: "history-list scroll" },
          _vm._l(_vm.history, function (historyItem) {
            return _c(
              "a",
              {
                key: historyItem.id,
                staticClass: "history-item-link",
                attrs: { href: _vm.historyUrl + historyItem.id, download: "" },
              },
              [
                _c("div", { staticClass: "history-item-left" }, [
                  _c("div", { staticClass: "history-item-name" }, [
                    _vm._v(_vm._s(historyItem.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "history-item-date" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("formatDate")(
                            historyItem.date,
                            "dd.MM.yyyy HH:mm:ss"
                          )
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("AppIcon", {
                  staticClass: "history-item-download",
                  attrs: { name: "download" },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }