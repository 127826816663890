var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "confirmation" }, [
    _c("div", { staticClass: "confirmation__wrapper-for-fourth-step" }, [
      _c(
        "div",
        { class: _vm.classesForConfirmationBody },
        [
          _vm.step == "one"
            ? [
                _c("div", { class: _vm.classesForText }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.options[
                          _vm.isContractOffer ? "is_offer" : _vm.contractStatus
                        ].text
                      ) +
                      "\n          "
                  ),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("spaced")(_vm.earnestPayment)) + " р."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "download-button",
                    attrs: {
                      download: "",
                      href: _vm.invoiceLink,
                      target: "_blank",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.goToStep("two")
                      },
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "download-button__icon",
                      domProps: { innerHTML: _vm._s(_vm.icons["download"]) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "download-button__text" }, [
                      _vm._v("Скачать счет"),
                    ]),
                  ]
                ),
              ]
            : _vm.step == "two"
            ? [
                _c("div", {
                  staticClass: "confirmation__icon",
                  domProps: { innerHTML: _vm._s(_vm.icons["uploadFile"]) },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "confirmation__text-info-wrapper" }, [
                  _c("div", { class: _vm.classesForText }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.options[
                            _vm.isContractOffer
                              ? "is_offer"
                              : _vm.contractStatus
                          ].confirmation
                        ) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "confirmation__info",
                      class: { "confirmation__info--error": !_vm.inputIsValid },
                    },
                    [
                      _vm._v(
                        "\n            1 файл формата jpeg, png, tiff, pdf и не более 10Мб\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "button", attrs: { for: _vm.inputId } },
                  [
                    _c("div", {
                      staticClass: "button__icon",
                      domProps: { innerHTML: _vm._s(_vm.icons["paperclip"]) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "button__text" }, [
                      _vm._v("Прикрепить файл"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      ref: "hidden-input",
                      staticClass: "confirmation__hidden-input",
                      attrs: { id: _vm.inputId, type: "file" },
                      on: { change: _vm.handleInput },
                    }),
                  ]
                ),
              ]
            : _vm.step == "three"
            ? [
                _c("div", { staticClass: "confirmation__file file" }, [
                  _c(
                    "div",
                    { staticClass: "file__name" },
                    [
                      !_vm.fileIsUploading
                        ? [_vm._v(_vm._s(_vm.fileName))]
                        : _c("img", {
                            attrs: {
                              src: require("@icons/loading.svg"),
                              width: "24",
                            },
                          }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.fileIsUploading
                    ? _c("img", {
                        staticClass: "file__dismiss",
                        attrs: {
                          src: require("../../assets/icons/dismiss.svg"),
                          alt: "dismiss.svg",
                        },
                        on: { click: _vm.dismissFile },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "button", on: { click: _vm.uploadFile } },
                  [
                    _c("div", {
                      staticClass: "button__icon",
                      domProps: { innerHTML: _vm._s(_vm.icons["sendFile"]) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "button__text" }, [
                      _vm._v("Отправить"),
                    ]),
                  ]
                ),
              ]
            : _vm.step == "four"
            ? [
                _c("div", {
                  staticClass: "confirmation__icon",
                  domProps: { innerHTML: _vm._s(_vm.icons["check"]) },
                }),
                _vm._v(" "),
                _c("div", { class: _vm.classesForText }, [
                  _vm._v("Спасибо, платёжка отправлена"),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.step == "one" && _vm.contractStatus !== "concluding"
        ? _c(
            "div",
            {
              staticClass: "footer-button",
              on: {
                click: function ($event) {
                  return _vm.goToStep("two")
                },
              },
            },
            [
              _c("div", {
                staticClass: "footer-button__icon",
                domProps: { innerHTML: _vm._s(_vm.icons["paperclip"]) },
              }),
              _vm._v(" "),
              _c("div", [_vm._v("Загрузить поручение")]),
            ]
          )
        : _vm.step == "two" || _vm.step == "three"
        ? _c(
            "a",
            {
              staticClass: "footer-button",
              attrs: { download: "", href: _vm.invoiceLink, target: "_blank" },
            },
            [
              _c("div", {
                staticClass: "footer-button__icon",
                domProps: { innerHTML: _vm._s(_vm.icons["download"]) },
              }),
              _vm._v(" "),
              _c("div", [_vm._v("Скачать счет повторно")]),
            ]
          )
        : _vm.step == "four" && _vm.contractStatus !== "concluding"
        ? _c(
            "div",
            {
              staticClass: "footer-button",
              on: {
                click: function ($event) {
                  return _vm.goToStep("two")
                },
              },
            },
            [
              _c("div", {
                staticClass: "footer-button__icon",
                domProps: { innerHTML: _vm._s(_vm.icons["paperclip"]) },
              }),
              _vm._v(" "),
              _c("div", [_vm._v("Загрузить поручение повторно")]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }