<template>
  <div class="icon"></div>
</template>

<script>
export default {
  name: "AppIcon",
  props: {
    name: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      default: "",
    },
  },
  async mounted() {
    let svg = "";

    if (this.src) {
      let src = this.src;

      if (src.startsWith("@images/")) {
        src = src.replace("@images/", ""); // HACK: для import надо чтобы "@images/" было указано явно
        svg = await import(/* webpackMode: "eager" */ "@images/" + src); // HACK: для import надо чтобы "@/images" было указано явно
      } else {
        src = src.replace("@icons/", ""); // HACK: для import надо чтобы "@icons/" было указано явно
        svg = await import(/* webpackMode: "eager" */ "@icons/" + src); // HACK: для import надо чтобы "@icons/" было указано явно
      }

      svg = svg.default;

      if (svg.startsWith("data:image/svg+xml;base64,")) {
        svg = svg.replace("data:image/svg+xml;base64,", "");
        svg = window.atob(svg);
      } else if (svg.startsWith("/") || svg.startsWith("http")) {
        // для тех svg, которые грузятся как "asset/resource"
        svg = `<img src="${svg}" />`;
      }
    } else {
      svg = await import(/* webpackMode: "eager" */ "@/assets/icons/colorless/" + this.name + ".svg");
      svg = svg.default;
    }

    this.$el.innerHTML = svg;
  },
};
</script>

<style scoped>
.icon {
  display: inline-flex;
  flex: none;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable selector-pseudo-class-no-unknown */
.icon > :deep svg,
.icon > :deep img {
  width: 100%;
  height: 100%;
}
/* stylelint-enable selector-pseudo-class-no-unknown */
</style>
