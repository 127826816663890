import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";

export const UserService = {
  async fetchInfo() {
    const url = "/me";

    const response = await axiosClient.get(url);

    const userInfo = response.data;

    if (!userInfo.organizations || userInfo.organizations?.length == 0) {
      throw new Error("Не удаётся получить данные организаций пользователя. Пожалуйста обратитесь к аутсорсеру.");
    }

    return userInfo;
  },

  async fetchPushSettings() {
    const url = "/user";

    const response = await axiosClient.get(url);

    return response.data.push;
  },

  async setPushSetting(key, value) {
    const url = `/user/push/${key}?data=${value}`;

    const csrf = await AuthService.fetchCSRF();

    await axiosClient.put(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
    });
  },

  async updateNotificationsSettings(newSettings) {
    const url = "/userUFA";

    const csrf = await AuthService.fetchCSRF();

    let formData = new FormData();

    for (let field in newSettings) formData.append(field, newSettings[field]);

    const response = await axiosClient.post(url, formData, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },

  async changeUserSettingsField(fieldName, value) {
    const fieldsUsingFormData = ["avatar", "position"];

    let url;
    let response;

    const csrf = await AuthService.fetchCSRF();

    if (fieldsUsingFormData.includes(fieldName)) {
      url = "/userUFA";

      let formData = new FormData();

      if (value instanceof File) {
        formData.append(fieldName, value, value.name);
      } else {
        formData.append(fieldName, value);
      }

      response = await axiosClient.post(url, formData, {
        headers: {
          "x-csrf-token": csrf,
        },
      });
    } else {
      url = `/user/${fieldName}?data=${value}`;

      response = await axiosClient.put(url, undefined, {
        headers: {
          "x-csrf-token": csrf,
        },
      });
    }

    return response.data;
  },

  async changePassword({ currentPassword, newPassword }) {
    const url = "/change-password";

    const csrf = await AuthService.fetchCSRF();

    let response;

    try {
      response = await axiosClient.put(
        url,
        { currentPassword, newPassword },
        {
          headers: {
            "x-csrf-token": csrf,
          },
        }
      );
    } catch (error) {
      if (error.response.status == 400) {
        throw new Error("Ошибка валидации", { cause: error.response.data });
      }
    }

    return response.data;
  },

  async serviceConnectionRequest(requestInfo) {
    const url = "/register";

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, requestInfo, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },
};
