<template>
  <div class="cab-textarea">
    <div class="cab-textarea__wrapper" :class="classesForWrapper">
      <textarea
        :id="name"
        ref="textarea"
        class="cab-textarea__textarea"
        :class="classesForTextarea"
        :style="{ maxHeight }"
        :value="value"
        :name="name"
        :placeholder="$attrs.placeholder"
        :rows="$attrs.rows"
        :maxlength="$attrs.maxlength"
        @input="onInput($event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
      ></textarea>

      <label :for="name" class="cab-textarea__label">
        {{ label }}
      </label>

      <AppIcon v-if="clearButton && value" name="close" class="cab-textarea__clear" @click.native="clear" />
    </div>

    <ExpandTransition>
      <div v-if="errorMessage" class="cab-textarea__error-message" v-text="errorMessage"></div>
    </ExpandTransition>
  </div>
</template>

<script>
import ExpandTransition from "../transitions/Expand.vue";
import AppIcon from "@/components/common/Icon.vue";

export default {
  name: "CabTextarea",

  components: {
    ExpandTransition,
    AppIcon,
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    maxHeight: {
      type: String,
      default: "76px",
    },

    errorMessage: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    clearButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: false,
      placeholderIsVisible: false,
    };
  },

  computed: {
    classesForWrapper() {
      return {
        "cab-textarea__wrapper--active": this.active,
        "cab-textarea__wrapper--error": this.errorMessage !== "",
        "cab-textarea__wrapper--disabled": this.disabled,
      };
    },

    classesForTextarea() {
      return {
        "cab-textarea__textarea--not--empty": this.value,
        "cab-textarea__textarea--visible-placeholder": this.placeholderIsVisible,
      };
    },
  },

  methods: {
    resizeTextarea() {
      const oldHeight = this.$refs["textarea"].style.height;
      this.$refs["textarea"].style.height = "auto";
      const newHeight = this.$refs["textarea"].scrollHeight;
      this.$refs["textarea"].style.height = oldHeight;

      requestAnimationFrame(() => {
        this.$refs["textarea"].style.height = newHeight + "px";
      });
    },

    onInput(value) {
      this.$emit("input", value);

      this.resizeTextarea();
    },

    onFocus() {
      this.active = true;
      this.placeholderIsVisible = true;

      this.$emit("focus");
    },

    onBlur() {
      this.active = false;
      this.placeholderIsVisible = false;

      this.$emit("blur");
    },

    clear() {
      this.$refs.textarea.focus();
      this.$emit("input", "");
    },
  },
};
</script>

<style scoped lang="scss">
.cab-textarea {
  &__wrapper {
    position: relative;
    overflow: auto;
    padding-top: 14px;
    border-bottom: 1px solid $grey-300;

    &--active {
      border-bottom: 1px solid $black;
    }

    &--error {
      border-bottom: 1px solid $red-500;
    }
  }

  &__textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    scrollbar-color: $grey-300 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $grey-300;
    }

    &::placeholder {
      opacity: 0;
      transition: opacity $short;
    }

    &--visible-placeholder {
      &::placeholder {
        opacity: 1;
      }
    }
  }

  &__label {
    position: absolute;
    top: 10px;
    left: 0;
    color: $grey-600;
    cursor: pointer;
    transition: $shortest;

    :focus ~ & {
      top: -2px;
      font-size: 12px;
    }

    [class$="--not--empty"] ~ & {
      top: -2px;
      font-size: 12px;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    color: $grey-400;
    cursor: pointer;
  }

  &__wrapper--disabled {
    border-bottom: 1px solid $grey-300;
    pointer-events: none;
  }

  &__wrapper--disabled &__label {
    color: $grey-400;
  }

  &__wrapper--disabled &__textarea {
    color: $grey-500;
  }

  &__error-message {
    margin-top: 6px;
    color: $red-500;
    font-size: 12px;
    white-space: pre-line;
  }
}
</style>
