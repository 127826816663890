<template>
  <div class="reference-faq">
    <div class="reference-faq__title">
      Ответы на часто встречающиеся вопросы пользователей о работе в приложениях «1С» через тонкий клиент
    </div>

    <div v-for="(question, index) in questions" :key="question.question" class="expand-panel">
      <div
        class="expand-panel__header"
        :class="{
          'expand-panel__header--active': opened.includes(index),
        }"
        @click="toggle(index)"
      >
        {{ question.question }}
        <img src="@icons/chevron-arrow-down.svg" />
      </div>
      <ExpandTransition>
        <div v-if="opened.includes(index)" class="expand-panel__content" v-html="question.answer"></div>
      </ExpandTransition>
    </div>
  </div>
</template>

<script>
import xor from "lodash-es/xor";

import ExpandTransition from "../../components/transitions/Expand.vue";

import questions from "./questions.json";

export default {
  name: "ReferenceFAQ",

  components: {
    ExpandTransition,
  },

  data() {
    return {
      questions,
      opened: [],
    };
  },

  methods: {
    toggle(index) {
      this.opened = xor(this.opened, [index]);
    },
  },
};
</script>

<style scoped lang="scss">
.reference-faq {
  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
  }

  @media (max-width: 600px) {
    &__title {
      font-size: 20px;
    }
  }
}

.expand-panel {
  width: 100%;
  border-bottom: 1px solid $grey-200;
  font-size: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    cursor: pointer;
    font-weight: 500;

    img {
      height: 30px;
      margin-left: 20px;
    }

    &--active img {
      transform: rotate(180deg);
    }
  }

  &__content {
    max-width: 900px;
    padding-bottom: 20px;
    color: $grey-700;

    a {
      color: $blue-500;
    }
  }
}
</style>
