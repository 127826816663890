var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab" },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "tab__loader-overlay" }, [
            _c("img", {
              attrs: { src: require("@icons/bubble-loader.svg"), width: "48" },
            }),
          ])
        : _vm.isError
        ? _c("div", [_vm._v("Error...")])
        : [
            _c(
              "div",
              { staticClass: "tab__top" },
              [
                _c(
                  "div",
                  { staticClass: "tab__top__structure" },
                  [
                    _vm.agents.length || _vm.searchStrAgents.length
                      ? _c("SearchBlock", {
                          on: { value: (val) => (_vm.searchStrAgents = val) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("AddNewAgentBlock", {
                      staticClass: "tab__add-agent",
                      attrs: { agents: _vm.agents, rights: _vm.rights },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.agents.length
                  ? _c("AgentsSlider", {
                      staticClass: "tab__slider",
                      attrs: {
                        agents: _vm.agents,
                        "is-at-start": _vm.sliderIsAtStart,
                        "is-at-end": _vm.sliderIsAtEnd,
                      },
                      on: {
                        "slide-prev": _vm.slidePrev,
                        "slide-next": _vm.slideNext,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.agents.length
              ? _c(
                  "div",
                  { staticClass: "button-manual" },
                  [
                    _c("span", { staticClass: "button-manual__title" }, [
                      _vm._v("Уполномоченные представители"),
                    ]),
                    _vm._v(" "),
                    _c("FileDownloadIcon", {
                      staticClass: "button-manual__download",
                      attrs: {
                        "contract-id": _vm.rights.contract_id,
                        "doc-id": _vm.rights.doc_id,
                        "form-id": "agents",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.agents.length && _vm.searchStrAgents.length
              ? _c("div", { staticClass: "not-found-block" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("span", { staticClass: "not-found-block__text" }, [
                    _vm._v("Нет результатов по вашему запросу"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.agents.length
              ? _c("AgentsRightsTable", {
                  attrs: {
                    title: "Сфера полномочий представителя Заказчика",
                    agents: _vm.agents,
                    rights: _vm.rights.authority,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showAllSections && _vm.agents.length
              ? [
                  _c("AgentsRightsTable", {
                    attrs: {
                      title: "Права пользователей в приложении БП",
                      agents: _vm.agents,
                      rights: _vm.rights.accounting.main,
                    },
                  }),
                  _vm._v(" "),
                  _c("AgentsRightsTable", {
                    attrs: {
                      title:
                        "Дополнительные профили пользователей в приложении БП",
                      agents: _vm.agents,
                      rights: _vm.rights.accounting.extra,
                    },
                  }),
                  _vm._v(" "),
                  _c("AgentsRightsTable", {
                    attrs: {
                      title: "Права пользователей в приложении ЗиУП",
                      agents: _vm.agents,
                      rights: _vm.rights.hrm,
                    },
                  }),
                  _vm._v(" "),
                  _c("AgentsRightsTable", {
                    attrs: {
                      title: "Права пользователей в приложении УНФ",
                      agents: _vm.agents,
                      rights: _vm.rights.unf,
                    },
                  }),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "not-found-block__fon" }, [
      _c("img", {
        staticClass: "not-found-block__fon__img",
        attrs: { src: require("@images/raw-svg/search.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }