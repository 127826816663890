var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("div", { staticClass: "root__title" }, [
        _vm._v(
          "Для просмотра раздела Документы установите мобильное приложение 1С:БО"
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.mobileAppDownloads, function (download) {
        return _c(
          "a",
          {
            key: download.url_download,
            staticClass: "root__link",
            attrs: { href: download.url_download, target: "_blank" },
          },
          [
            download.type == "ios"
              ? _c("img", {
                  attrs: {
                    src: require("../../assets/images/appstore.png"),
                    alt: "appstore.png",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            download.type == "android"
              ? _c("img", {
                  attrs: {
                    src: require("../../assets/images/googleplay.png"),
                    alt: "googleplay.png",
                  },
                })
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }