<template>
  <svg class="eye-icon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      v-if="isStriked"
      class="eye-icon__path"
      d="M8.0013 3C4.668 3 1.8213 5.0733.668 8c1.1533 2.9267 4 5 7.3333 5 3.3333 0 6.18-2.0733 7.3333-5-1.1533-2.9267-4-5-7.3333-5zm0 8.3333C6.1613 11.3333 4.668 9.84 4.668 8c0-1.84 1.4933-3.3333 3.3333-3.3333 1.84 0 3.3333 1.4933 3.3333 3.3333 0 1.84-1.4933 3.3333-3.3333 3.3333zM6.0013 8c0-1.1067.8933-2 2-2s2 .8933 2 2-.8933 2-2 2-2-.8933-2-2z"
    />
    <path
      v-else
      class="eye-icon__path"
      d="M8.0046 4.6667c1.8409 0 3.3349 1.4933 3.3349 3.3333 0 .4333-.0867.84-.2401 1.22l1.9475 1.9467c1.0072-.84 1.8008-1.9267 2.2877-3.1667-1.1538-2.9267-4.0018-5-7.3366-5a7.7701 7.7701 0 00-2.6546.4667l1.4407 1.44c.3802-.1534.787-.24 1.2205-.24zm-6.6697-1.82l1.5207 1.52.3068.3066A7.8697 7.8697 0 00.668 8c1.1538 2.9267 4.0018 5 7.3366 5 1.0338 0 2.021-.2 2.9214-.56l.2801.28 1.9542 1.9467.8471-.8467L2.182 2l-.847.8467zm3.6884 3.6866L6.057 7.5667A1.8798 1.8798 0 006.0037 8c0 1.1067.8938 2 2.001 2 .1467 0 .2934-.02.4335-.0533L9.472 10.98c-.4469.22-.9405.3533-1.4674.3533C6.1638 11.3333 4.6698 9.84 4.6698 8c0-.5267.1334-1.02.3535-1.4667zm2.8746-.52l2.101 2.1.0133-.1066c0-1.1067-.8937-2-2.0009-2l-.1134.0066z"
    />
  </svg>
</template>

<script>
export default {
  name: "EyeIcon",

  props: {
    isStriked: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.eye-icon {
  width: 16px;
  cursor: pointer;
  fill: $grey-700;
  user-select: none;
}
</style>
